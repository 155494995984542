import { useEffect, useState } from "react";
import { useLanguagesContext } from "../../../contexts/LanguagesContext";
import useUserSettings from "../../../hooks/useUserSettings";
import Button from "../../general/Button";
import Modal from "../../general/Modal";
import useLanguages from "../../../hooks/useLanguages";

const ModalContent = ({props}) => {
    const {autoplayAudio, targetLanguage, setAutoPlayAudio, closeFunction} = props;
    const {updateUserSettings} = useUserSettings();

    const {userLanguages, setUserLanguages} = useLanguagesContext();
    const {updateLanguageSettings} = useLanguages();

    const autoPlayAudioChange = async (e) => {
        setAutoPlayAudio(e.target.checked); 
        await updateUserSettings({settings: {autoplayGameAudio: e.target.checked}});
    }

    const [displayPinyin, setDisplayPinyin] = useState(null);

    useEffect(()=>{
        console.log("User languages: ", userLanguages, targetLanguage,userLanguages !== null && targetLanguage !== null)
        if (userLanguages !== null && targetLanguage !== null){
            for (const lang of userLanguages){
                console.log(lang['target_language'] === "zh")
                if (lang['target_language'] === targetLanguage){
                    console.log("Database lang: ", lang)
                    if (lang.hasOwnProperty('display_pinyin')){
                        setDisplayPinyin(lang['display_pinyin']);
                    } 
                }
            }
        }
    },[userLanguages, targetLanguage]);

    const displayPinyinChange = async (e) => {
        setDisplayPinyin(e.target.checked);
        let newUserLanguages = userLanguages.map((lang) => {
            if (lang['target_language'] === targetLanguage){
                lang['display_pinyin'] = e.target.checked;
            }
            return lang;
        });
        setUserLanguages(newUserLanguages);
        await updateLanguageSettings({targetLanguage: targetLanguage, settings:{'display_pinyin': e.target.checked}});
    };

    return (
        <>
            <div className="flex flex-col gap-6">
                <h3 className="border-b-2 pb-2 border-solid border-gray-200">Game settings</h3>
                <div className=" flex flex-col gap-3">
                    <div className="flex flex-row justify-between place-items-center gap-4">
                        <div className="muted-text text-xl font-semibold">Autoplay audio</div>
                        <input type="checkbox" checked={autoplayAudio} onChange={autoPlayAudioChange} className="form-checkbox h-8 w-8 shadow-xl rounded-xl overflow-hidden  accent-persian-green" />
                    </div>
                </div>
                {targetLanguage === "zh" && 
                    <>
                        <div className="flex flex-row justify-between place-items-center gap-4">
                            <div className="muted-text text-xl font-semibold">Show pinyin</div>
                            <input type="checkbox" checked={displayPinyin} onChange={displayPinyinChange} className="form-checkbox h-8 w-8 shadow-xl rounded-xl overflow-hidden  accent-persian-green" />
                        </div>
                    </>
                }
                <div className="flex flex-row justify-center">
                    <Button text="Finished" color="green" onClick={closeFunction} />
                </div>
            </div>
        </>
    )
};

const GameSettingsModal = (props) => {

    return (
        <>
            <Modal size="big" content={<ModalContent props={props} />} closeFunction={props.closeFunction} />
        </>
    )
};

export default GameSettingsModal;
