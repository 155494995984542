import React, {useEffect, useState} from 'react';
import useDecks from '../../../../hooks/useDecks';
import useStatistics from '../../../../hooks/useStatistics';
import useQueryStrings from '../../../../hooks/useQueryStrings';
import {Link} from 'react-router-dom';
import QuizEndScreen from './QuizEndScreen';
import Button from '../../../general/Button';
import QuizQuestions from './QuizQuestions';
import ScoreScreen from '../../../../components/vocabulary/games/common-assets/ScoreScreen';
import {useLanguagesContext} from '../../../../contexts/LanguagesContext';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../../../../components/general/Icon';
import QuizHelpModal from './modals/QuizHelpModal';

const QuizGame = ({reviewFinished, setReviewFinished, isPremium, currentCardIndex, setCurrentCardIndex, nbOfAnswers, setNbOfAnswers, cardsLeft, setCardsLeft, mistakes, score, setScore, gameScreen, setGameScreen, setMistakes, audioLoading, autoPlayPronunciations, globalHandleCorrectAnswer, globalHandleWrongAnswer, globalHandleSkipAnswer, isInMix, globalChosenCard, googleTextToSpeechClick, playMode, setPlayMode, showLoadingScreen, setShowLoadingScreen, cards, deck, decks, loadingItems, refreshData, howManyItems, backToPlayPageLink, playModeArray, id, type}) => {
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions} = useLanguagesContext();
    const {fetchVocabularyInTargetLanguage} = useDecks();
    const {addQuizStatForCurrentUser} = useStatistics(); 
    const [targetLanguage, setTargetLanguage] = useState(globalSelectedTargetLanguage);
    const [nbOfItems, setNbOfItems] = useState(0);
    const [nbOfSkips, setNbOfSkips] = useState(0);
    const [reviewMode, setReviewMode] = useState(null);

    const [error, setError] = useState(null);

    // Counting docs
    useEffect(()=>{
        if (cards !== null && !isInMix){
            setCardsLeft(cards.length);
            if (cards.length < minimumNbOfItems){
                setError("At least 4 items are needed in order to play in Quiz mode.");
            }
        }  
    }, [cards, isInMix]);

    useEffect(()=>{
        if (autoPlayPronunciations === true && currentCardIndex !== null && cards !== null && !audioLoading && playMode === "target_first"){
            googleTextToSpeechClick(cards[currentCardIndex]);
        }
    },[autoPlayPronunciations, currentCardIndex, cards, playMode]);


    let minimumNbOfItems = 4;  //need this to play

    const playAgain = async () =>{
        await refreshData();
        setCardsLeft(cards.length);
        setScore(0);
        setCurrentCardIndex(0);
        setNbOfAnswers(0);
        setGameScreen("game");
    }

/*     useEffect(()=>{
        let r = await getQueryStrings(); 
        if ("lang" in r){
            setTargetLanguage(r.lang);
        } 
        if ("mode" in r){
            if (r.mode === "worst" || r.mode === "best"){
                setReviewMode(r.mode);
            } else {
                setReviewMode("worst");
            }
        } else {
            setReviewMode("worst");
        }
    },[]); */

    const [showHelpModal, setShowHelpModal] = useState(false);

    const helpClick = () => {
        setShowHelpModal(true);
    }

    const closeHelpModal = () => {
        setShowHelpModal(false);
    }

    /*
            {(!loadingItems && !showLoadingScreen && !isInMix) &&
            <div className="flex flex-row justify-center gap-6">
                <div>
                    <span className="text-gray-600 font-bold">Score</span>: {score}
                </div>
                <div>
                    <span className="text-gray-600 font-bold">Items left</span>: {cardsLeft}
                </div>
            </div>
            }
    */

    return (
        <>

            {(!loadingItems && !showLoadingScreen) &&
                <>
                <div className="p-0 w-full  sm:p-0 flex flex-col items-center justify-center h-full gap-4">
                    {error !== null ? 
                    <>
                        <div>
                            <div className="flex flex-col justify-center place-items-center">
                                <div className="text-base m-auto p-4 text-center">
                                    <div className="max-h-xs text-black text-center flex flex-col gap-3">
                                        <p>{error}</p>
                                        <div className="flex flex-row justify-center">
                                        <Link className="no-underline" to={backToPlayPageLink} ><Button text="Go to play page" color="green" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                  
                    </> 
                    :
                    <>  
                        {gameScreen === "game" &&
                            <>
                                <QuizQuestions playModeArray={playModeArray} mistakes={mistakes} setMistakes={setMistakes} isPremium={isPremium} globalHandleSkipAnswer={globalHandleSkipAnswer} isInMix={isInMix} globalHandleCorrectAnswer={globalHandleCorrectAnswer} globalHandleWrongAnswer={globalHandleWrongAnswer} globalChosenCard={globalChosenCard} audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} playMode={playMode} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} reviewMode={reviewMode} addQuizStatForCurrentUser={addQuizStatForCurrentUser} dataType={type} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} nbOfSkips={nbOfSkips} setNbOfSkips={setNbOfSkips} setGameScreen={setGameScreen} cards={cards} score={score} setScore={setScore} />
                            </>
                        }
                        {(gameScreen === "endscreen" && !isInMix) && 
                            <>
                                <ScoreScreen score={score} nbOfAnswers={cards.length} backToPlayPageLink={backToPlayPageLink} playAgainClick={playAgain} />
                            </>
                        }
                    </>
                    } 
                </div>
                </>
            }
    </>
    )
}

export default QuizGame;