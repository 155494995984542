import { useEffect, useState } from "react";
import EmailVerification from "../../components/settings/EmailVerification";
import useUserSettings from "../../hooks/useUserSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

const AccountSettings = () => {

    const {updateUserSettings, getUserSettings} = useUserSettings();
    const [loading, setLoading] = useState(true);
    const [autoplayAudio, setAutoplayAudio] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        console.log("Fetching user settings")
        let settings = await getUserSettings();
        console.log("Settings: ", settings)
        if (settings !== null && settings !== false && settings.hasOwnProperty("autoplayGameAudio")){
          setAutoplayAudio(settings.autoplayGameAudio);
        }
        setLoading(false);
      }
      fetchData();
    }, []);

    const autoPlayAudioChange = async (e) => {
      setAutoplayAudio(e.target.checked); 
      setLoading(true);
      await updateUserSettings({settings: {autoplayGameAudio: e.target.checked}});
      setLoading(false);
    }

    return (
      <>
          <div className="  flex flex-col gap-3">
              <h1>Account settings</h1>
              {loading && <div>
                  <div className="h-screen w-screen fixed flex flex-col place-items-center justify-center text-4xl">
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </div>
                </div>}
              <div className="flex flex-col gap-12 place-items-center">
                <div className="flex flex-col gap-3">
                  <h3>Game settings</h3>
                  {!loading &&
                    <div className="flex flex-row place-items-center gap-4">
                      <div className="muted-text  font-semibold">Autoplay audio</div>
                      <input type="checkbox" checked={autoplayAudio} onChange={autoPlayAudioChange} className="form-checkbox h-4 w-4  accent-persian-green" />
                      
                    </div>
                  }
                </div>
                <div>
                  <h3>Email verification</h3>
                  <div className="p-3">
                      <EmailVerification />
                  </div>
                </div>
              </div>

          </div>
      </>
    )
  }
  
  export default AccountSettings;