import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from '../../../general/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/pro-duotone-svg-icons';
import { faCog, faX } from '@fortawesome/pro-solid-svg-icons';
import { faVolume } from '@fortawesome/pro-solid-svg-icons';
import { faVolumeSlash } from '@fortawesome/pro-solid-svg-icons';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import GameSettingsModal from '../../modals/GameSettingsModal';

const HelpMenu = ({isPremium, backToPlayPageLink, targetLanguage, autoPlayPronunciations, setAutoPlayPronunciations,  autoPlayPronunciationsClick, helpClick, playMode}) => {
   
    /*
 <div className="text-lg" onClick={helpClick}>
                                    <div data-tip="How to play" data-for="flashcard-game-tooltip">
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faQuestion} />} />
                                    </div>
                                </div>
    */

    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const openGameSettingsClick = () => {
        setShowSettingsModal(true);    
    }

    const closeGameSettingsModal = () => {
        setShowSettingsModal(false);
    };


    return (
        <>
            {backToPlayPageLink !== null &&
                <>
                    <div className="w-full">
                        <ReactTooltip id="flashcard-game-tooltip" effect="solid" />      
                            <div className=" flex text-lg lg:text-3xl flex-row justify-end gap-12">
                                <div onClick={openGameSettingsClick} data-tip="Settings" data-for="flashcard-game-tooltip">
                                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faCog} />} />
                                </div>
                               
                                {backToPlayPageLink !== null &&
                                    <div className="">
                                        <Link to={backToPlayPageLink}>
                                            <div data-tip="Go back to play page" data-for="flashcard-game-tooltip">
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faX} />} />
                                            </div>
                                        </Link>
                                    </div>
                                }
                            </div>
                    </div>  
                </>
            }  
            {showSettingsModal &&
                <>
                    <GameSettingsModal targetLanguage={targetLanguage} autoplayAudio={autoPlayPronunciations} setAutoPlayAudio={setAutoPlayPronunciations} closeFunction={closeGameSettingsModal} />
                </>
            }
        </>
    )
}

export default HelpMenu 