import React, { useState } from 'react';
import Button from './Button';
import GeneralFeedback from '../feedback/modals/GeneralFeedback';

const FeedbackButton = (props) => {

    const {text} = props;
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    return (
        <>
            <Button text={text} color="green" onClick={()=>setShowFeedbackModal(true)} />
            {showFeedbackModal &&
                <>
                    <GeneralFeedback closeFunction={()=>setShowFeedbackModal(false)} />
                </>
            }
        </>
    );
};

export default FeedbackButton;