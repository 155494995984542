import React, {useEffect, useState} from 'react';
import useQueryStrings from '../../hooks/useQueryStrings';
import queryString from 'query-string';
import TranslatorWidget from '../../components/widgets/translator/TranslatorWidget';
import YalangoMovie from '../../assets/svg/yalango/YalangoMovie.svg';
import Skeleton from 'react-loading-skeleton';
import {useNavigate, useLocation} from 'react-router-dom';
import ImportCSVToDeck from '../decks/mydecks/ImportCSVToDeck';
import {motion} from 'framer-motion'; 
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {faArrowUp} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WatchYouTube = () => {
    //const {getQueryStrings} = useQueryStrings();
    const navigate = useNavigate();
    const location = useLocation();
    const [ytId, setYtId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [urlInput, setUrlInput] = useState("");
    const [page, setPage] = useState("search");

    const [showCreateDeckFeature, setShowCreateDeckFeature] = useState(true);
    const [showTranslator, setShowTranslator] = useState(true);

    const findYouTubeIdInUrl = (url) => {
        let normalsplit = url.split("watch?v=");
        if (normalsplit.length === 2){
            let temp_id = normalsplit[1];
            temp_id = temp_id.split("&")[0];
            return temp_id;
        } else {
            let shortersplit = url.split("youtu.be/");
            if (shortersplit.length === 2){
                let temp_id = shortersplit[1];
                temp_id = temp_id.split("?")[0];
                return temp_id; 
            }
        }
        return null;
    }

    const urlInputOnChange = (e) => {
        let url = e.target.value;
        setUrlInput(url);
        let id = findYouTubeIdInUrl(url);
        if (id !== null && id !== undefined){
            navigate("/watch?v="+id);
        }
    }

    const changeVideoClick = () => {
        navigate("/watch");
    }

    useEffect(()=>{
        const getQueryStrings = () => {
            const parsed_strings = queryString.parse(location.search);
            return parsed_strings;
        }

        const fetchData = async () => {
            setLoading(true);
            let r = getQueryStrings();
            if ('v' in r){
                setYtId(r['v'].split("&")[0]);
                setPage("video");
            } else {
                setPage("search");
                setYtId(null);
                setUrlInput("");
            }
            setLoading(false);
            setTimeout(()=>{window.scrollTo(0, 0)},100);
        }

        fetchData();

    },[location]);

    const cardClass = "p-8  border-solid border-gray-200 border-2 rounded-lg shadow-lg";
    const [translatedWords, setTranslatedWords] = useState([]);
    //
    return (
        <>
            <div className="  min-h-screen h-full flex flex-col gap-4">
                {page === "video" &&
                    <div onClick={changeVideoClick} className="text-xs cursor-pointer underline">
                        Change video
                    </div>
                }
                
                {!loading ?
                    <div className="flex h-full flex-col justify-center place-items-center gap-4">
                        {page === "search" &&
                            <>
                                <h1>Study a YouTube video</h1>
                                
                                <div>
                                    <img src={YalangoMovie} alt="Yalango movie" className="w-5/6 m-auto p-4 sm:w-96 mx-auto my-0" />
                                </div>
                                <div className="h-full flex w-5/6 m-auto flex-col gap-3 justify-center">
                                    <div className="font-semibold text-gray-600 text-lg text-center">
                                        Start by pasting a YouTube URL below.
                                    </div>
                                    <div>
                                        <input  onChange={urlInputOnChange} value={urlInput} type={"text"} className="focus:border-light-blue-500 text-center shadow-lg focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-black placeholder-gray-500 border border-gray-200 rounded-lg text-base lg:text-lg p-3 lg:p-4" placeholder={"Paste a YouTube URL"}></input>
                                    </div>
                                </div>
                            </>
                        } 
                        {page === "video" && ytId !== null && ytId !== undefined &&
                            <>
                                <div className="flex w-full flex-col  justify-center gap-4">
                                    <div className="flex w-full flex-row justify-center">
                                        <div className={cardClass + " w-full sm:w-5/6 xl:w-1/2 m-auto h-full"}>
                                            <h2>Watch the video</h2>
                                            <iframe className="aspect-video w-full h-full" src={"https://www.youtube-nocookie.com/embed/"+ytId} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-5/6 xl:w-1/2 m-auto flex flex-col xl:flex-row justify-center gap-4">
                                        <div className={cardClass}>
                                            <h2>Translate words</h2>
                                            <TranslatorWidget translatedWords={translatedWords} setTranslatedWords={setTranslatedWords} showHeadline={false} showDescription={false} />
                                        </div>
                                        <div className={cardClass}>
                                            <h2>Save words to your decks</h2>
                                            <ImportCSVToDeck translatedWords={translatedWords} type="table" ytId={ytId} hideTitles={true} hideDescription={true} mode="create-deck" design="embed" />
                                        </div>
                                    </div>
                                </div>
                           
                            </>
                        }
                    </div>
                : 
                    <>
                        <div className="">
                            <Skeleton count={1} height={150} />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default WatchYouTube;