import React, {useState, useEffect} from 'react';
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/general/Button';
import SimpleErrorBar from '../general/SimpleErrorBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import useLogs from '../../hooks/useLogs';

const InputField = ({id, type, text, onChangeFunction, value}) => {

  return (
      <>
          <input key={id} onChange={onChangeFunction} value={value} type={type} className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 px-2 m-2" placeholder={text}></input>
      </>
  )
}

function LoginComponent(props) {
    const [redirectUrl, setRedirectUrl] = useState('/dashboard');
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { login, currentUser } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(()=>{
        if (props.redirect !== null){
            setRedirectUrl(props.redirect);
        }

        if (currentUser === null){
            setLoading(false);
        } else {
            navigate(redirectUrl);
        }
    },[props.redirect, redirectUrl, currentUser, navigate]);

    const emailInputFunction = (e) => {
        setEmail(e.target.value);
    }

    const passwordInputFunction = (e) => {
        setPassword(e.target.value);
    }
    
    const {saveErrorLog} = useLogs();

    const loginFunction = async () => {
        
        if (email === ""){
            setError("Email cannot be blank.");
            return;
        } 
        if (password === ""){
            setError("Password cannot be blank.");
            return;
        }
        setLoading(true);
        await login(email, password).then(()=>{
            navigate(redirectUrl);
        }).catch((error)=>{
            setError("Wrong email or password.");
            saveErrorLog({errorObject: error, functionName: "loginFunction"});
        });
        setLoading(false);
    }

    const checkIfEnter = (event) => {
        if (event.key === 'Enter') {
            loginFunction();
        };
    }

    return (
        <>
            <div className="flex flex-col justify-center h-full " onKeyPress={checkIfEnter}>
                {!loading && 
                    <div className="m-auto">
                        <h1>Login </h1>
                        {error !== "" && 
                            <SimpleErrorBar message={error} />
                        }
                        <InputField id="email_input" type="email" text="Email" value={email} onChangeFunction={emailInputFunction} />
                        <InputField id="password_input" type="password" text="Password" value={password} onChangeFunction={passwordInputFunction} />
                        <div className="flex flex-col gap-2 align-center text-center">
                            <Button color="green" text="Login" onClick={loginFunction}></Button>
                            <Link to="/login/forgotten-password">Forgot your password?</Link>
                            <Link to="/register">Create an account instead</Link>
                        </div>
                    </div>
                }
                {(loading) &&
                <>
                    <div className="h-screen overflow-hidden flex flex-col justify-center text-center text-3xl">
                        <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                    </div>
                </>
                }
            </div>  
        </>
    )
}

export default LoginComponent;
