import React, {useState, useEffect} from 'react';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlay } from '@fortawesome/pro-duotone-svg-icons';
import YalangoImage from '../../assets/svg/yalango/yalango_thumbs_up_without_circle.svg';
import useDecks from '../../hooks/useDecks';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

const MistakesCard = () => {

    const [dbLoading, setDbLoading] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showList, setShowList] = useState(false);
    const [mistakes, setMistakes] = useState([]);
    const {getXMistakesToReviewFromLastYDaysApi} = useDecks();
    let { globalSelectedTargetLanguage, activeUserLanguages, allLanguages } = useLanguagesContext();

    useEffect(()=>{
        const fetchData = async () => {
            setDbLoading(true);
            let days = 7;
            let r = await getXMistakesToReviewFromLastYDaysApi(itemsPerPage, days, globalSelectedTargetLanguage);
            setMistakes(r["cards"]);
            setDbLoading(false);
        }

        if (globalSelectedTargetLanguage !== null && itemsPerPage !== null){
            //fetchData(); //not needed for now
        }

    },[globalSelectedTargetLanguage, itemsPerPage]);
    
    //{dbLoading && <Skeleton count={10} height={50} />}

    /*

                <div className="flex flex-row gap-8 items-start">
                       
                <div className="flex flex-col gap-4">
                    
                        
                    <div className="flex flex-col gap-1">
                        {!dbLoading && globalSelectedTargetLanguage !== null && mistakes !== null && mistakes.length > 0 ?
                            <>
                                    <div className="flex flex-row gap-4">
                                        <div>
                                            <div onClick={()=>setShowList(!showList)} className="text-white text-sm mb-4 flex flex-row gap-3 cursor-pointer">
                                                <div>
                                                    <FontAwesomeIcon icon={showList ? faChevronUp : faChevronDown}  />
                                                </div>
                                                <div>
                                                {showList ? "Hide" : "Show"} next {mistakes.length} mistakes in your queue.
                                                </div>
                                            </div>
                                            <div className={`${showList ? 'opacity-100 max-h-full ' : 'opacity-0 max-h-0 scale-0 '} h-full transform transition-all ease-in-out duration-300 ml-5 `}>
                                            {mistakes.map((item, index) => {
                                                return (
                                                    <>

                                                            <div key={index} className="flex flex-row justify-between items-center p-2 rounded-md">
                                                                <div className="flex flex-col">
                                                                    <div className="text-base text-white font-semibold"><Link to={`/my-vocabulary/${globalSelectedTargetLanguage}/item/${item.id}`}>{item.target}</Link></div>
                                                                    <div className="text-sm text-white italic">{item.source}</div>
                                                                </div>
                                                            </div>

                                                    </>
                                                )
                                            })}
                                            </div>
                                        </div>
                                        <div>
                                        
                                        </div>
                                    </div>
                                </>
                            :
                            <>
                            
                            {!dbLoading &&
                            <div className="text-center text-white ">No mistakes ready for review.</div>
                            }
                            <div className="text-white text-center">   
                         {dbLoading && "Loading..."}
                         </div>
                            </>                        
                        }
                    </div>
                </div>
                </div>
    */
    return (
        <>
            <div className="p-8 h-full  bg-gradient-to-r from-red-800 to-burnt-sienna w-full shadow-xl flex flex-col gap-6 rounded-xl transition-all ease-in-out duration-300">
                        <div className="font-semibold text-base text-white flex flex-col gap-4">
                            {globalSelectedTargetLanguage === null ? <Skeleton count={1} /> : 
                                <>
                                    <Link className="no-underline" to={`/my-vocabulary/${globalSelectedTargetLanguage}/review-mistakes?back=dashboard`}>
                                        <div className="flex flex-row justify-center gap-3">
                                            <div>
                                                <FontAwesomeIcon icon={faTriangleExclamation} className="" />
                                            </div>
                                            <div>
                                                Review mistakes
                                            </div>
                                        </div>
                                    </Link>
                                </>
                            }
                        
                        </div>
                    
                <div>
                    <img src={YalangoImage} alt="Tim from Yalango" className="w-24 mx-auto" />
                </div>

            </div>
        </>
    )
}

export default MistakesCard;