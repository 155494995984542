import React, { useContext, useState, useEffect } from "react";
import { projectAuth, projectFunctions } from "../firebase/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import YalangoSvg from '../assets/svg/yalango/yalango.svg';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isPremium, setIsPremium] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSplash, setShowSplash] = useState(true);
  const [error, setError] = useState(null);

  useEffect(()=>{
    if (currentUser === null){
      // reset
      setIsPremium(null);
      setError(null);
    }
  },[currentUser])

  const signup = async (email, password) => {
    return projectAuth.createUserWithEmailAndPassword(email, password).then(async (data)=>{
      //await verifyEmail(data.user);
    });
  }

  function login(email, password) {
    return projectAuth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return projectAuth.signOut();
  }

  function deleteAccount() {
    return projectAuth.delete();
  }

  function resetPassword(email) {
    //return projectAuth.sendPasswordResetEmail(email);
    let func = projectFunctions.httpsCallable("sendForgottenPasswordEmail");
    return func({'email': email});
  }

  const verifyEmail = async (user) => {
    //return currentUser.sendEmailVerification();
    let func = projectFunctions.httpsCallable("sendVerifyEmailLinkApi");
    await func({'uid': user.uid}).catch(err=>console.log(err));
    return true;
    
  }

  const checkIfVerifiedEmail = async () => {
    if (currentUser === null || currentUser === undefined){return null}
    await currentUser.reload(); 
    const user = projectAuth.currentUser;
    setCurrentUser(user);
    return user.emailVerified;
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  const checkIfPremiumUser = async (user) => {
    if (user === null){return false}
    if (user === undefined){
      user = currentUser;
    }
    let r = await user.getIdToken(true);
    let decoded = await user.getIdTokenResult();
    let premium = false;
    if (decoded.claims.hasOwnProperty("stripeRole") && decoded.claims.stripeRole === "premium"){
        premium = true;
    };
    setIsPremium(premium);
    return premium;
}

  useEffect(() => {
    let timer = null;
    const hideSplash = async () => {
      timer = setTimeout(()=>{
        setShowSplash(false);
      },0);
      
    }
    const unsubscribe = projectAuth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      checkIfPremiumUser(user);
      setLoading(false);
      hideSplash();
    })

    return ()=>{
      unsubscribe();
      clearTimeout(timer);
    }
  }, []);

  useEffect(()=>{
    const check = async () => {
      await checkIfPremiumUser(currentUser);
    }

    check();
  },[currentUser])


  const value = {
    currentUser,
    deleteAccount,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword, 
    verifyEmail, 
    isPremium, 
    checkIfPremiumUser, 
    checkIfVerifiedEmail
  }

  /*
<div className="fixed left-0 top-0 h-screen w-screen z-50 overflow-hidden flex flex-col justify-center gap-8 text-center text-3xl">
                <div>
                  <img src={YalangoSvg} alt="Yalango logo" className="w-64 lg:w-96 m-auto" />
                </div>
                <div className="flex flex-row text-xl place-items-center font-bold muted-text justify-center gap-4">
                  <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                  <div>
                    Loading
                  </div>
                </div>
            </div>
  */

  return (
    <AuthContext.Provider value={value}>
      {(!loading && error === null) && children}
        {error !== null &&
        <>
          <div>Error: {error}</div>
        </>
        }
        {showSplash &&
          <>
            <div className="fixed left-0 top-0 h-screen w-screen z-50 overflow-hidden flex flex-col justify-center gap-8 text-center text-3xl">
                <div>
                  <img src={YalangoSvg} alt="Yalango logo" className="w-64 lg:w-96 m-auto" />
                </div>
                <div className="flex flex-row text-xl place-items-center font-bold muted-text justify-center gap-4">
                  <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                  <div>
                    Loading
                  </div>
                </div>
            </div>
          </>
        }
    </AuthContext.Provider>

  )
}