import { jsPDF } from 'jspdf';
import React from 'react';
import NotoSansSCRegular from '../components/fonts/NotoSansSC-Regular'; // Adjust the path accordingly
import useLogs from './useLogs';

const usePdfs = () => {

  const {addExportDeckLog} = useLogs();

  const generateDeckListPdf = ({ title, items, targetLanguage, sourceLanguage, deckId }) => {
    const doc = new jsPDF();
    // Add the custom Noto Sans font to jsPDF
    doc.addFileToVFS('NotoSansSC-Regular.ttf', NotoSansSCRegular);
    doc.addFont('NotoSansSC-Regular.ttf', 'NotoSans', 'normal');
    doc.setFont('NotoSans'); // Use Noto Sans as the default font
    const margin = 10;
    const lineHeight = 10;
    const extraSpaceBetweenRows = 10; // Increased additional space between rows
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    let y = margin + 20;

    // Add title
    addTitle({ doc, title, pageWidth, y });
    y += lineHeight * 2;

    // Add table headers
    addTableHeaders({ doc, sourceLanguage, targetLanguage, pageWidth, y });
    y += lineHeight;

    // Add items with alternating background colors and page breaks
    doc.setFontSize(12);
    items.forEach((item, index) => {
      if (y + lineHeight > pageHeight - margin - 20) {
        addFooter({ doc, pageWidth, pageHeight });
        doc.addPage();

        // Reset styles after page break
        doc.setFontSize(12); // Reset font size
        doc.setTextColor(0); // Reset text color
        doc.setFillColor(255, 255, 255); // Reset fill color
        //doc.setFont('helvetica', 'normal'); // Reset font

        y = margin + 20 + lineHeight * 2;
        addTableHeaders({ doc, sourceLanguage, targetLanguage, pageWidth, y: y - lineHeight });
        doc.setFontSize(12); // Reset font size
        // Add a subtitle line under the table headers
        doc.setLineWidth(0.5);
        doc.setDrawColor(150); // A lighter color for the subtitle line
        doc.line(margin, y, pageWidth - margin, y);
        y += lineHeight + extraSpaceBetweenRows; // Adjust for new page
      }

      // Set background color for every second row
      if (index % 2 === 1) {
        doc.setFillColor(230, 230, 230); // Light gray color
        doc.rect(margin, y - lineHeight + 2, pageWidth - margin * 2, lineHeight, 'F');
      }

      const sourceX = (pageWidth / 4) - (doc.getTextWidth(item.source) / 2);
      const targetX = (3 * pageWidth / 4) - (doc.getTextWidth(item.target) / 2);
      doc.text(item.source, sourceX, y);
      doc.text(item.target, targetX, y);

      // Reset fill color for the next row
      doc.setFillColor(255, 255, 255); // Reset to white background

      y += lineHeight;
    });

    // Add the footer on the last page
    addFooter({ doc, pageWidth, pageHeight });

    // Save the PDF
    doc.save(`${title} - List.pdf`);

    // Log
    addExportDeckLog({ deckId: deckId, exportType: "pdf-list" });
  };

  const addTitle = ({ doc, title, pageWidth, y }) => {
    doc.setFontSize(22);
    doc.setTextColor(40);
    const titleX = (pageWidth / 2) - (doc.getTextWidth(title) / 2);
    doc.text(title, titleX, y);
    doc.setLineWidth(0.5);
    doc.line(10, y + 5, pageWidth - 10, y + 5);
  };

  const addTableHeaders = ({ doc, sourceLanguage, targetLanguage, pageWidth, y }) => {
    doc.setFontSize(14);
    //doc.setFont('helvetica', 'bold');
    const sourceX = (pageWidth / 4) - (doc.getTextWidth(sourceLanguage) / 2);
    const targetX = (3 * pageWidth / 4) - (doc.getTextWidth(targetLanguage) / 2);
    doc.text(sourceLanguage, sourceX, y);
    doc.text(targetLanguage, targetX, y);
    //doc.setFont('helvetica', 'normal'); // Reset to normal font weight
  };

  const addFooter = ({ doc, pageWidth, pageHeight }) => {
    doc.setFontSize(10);
    doc.setTextColor(150);
    const footerText = 'Created with yalango.com';
    const footerX = (pageWidth / 2) - (doc.getTextWidth(footerText) / 2);
    doc.text(footerText, footerX, pageHeight - 10);
  };

  const generateDeckQuizPdf = ({ title, items, targetLanguage, sourceLanguage, deckId }) => {
    const doc = new jsPDF();
    // Add the custom Noto Sans font to jsPDF
    doc.addFileToVFS('NotoSansSC-Regular.ttf', NotoSansSCRegular);
    doc.addFont('NotoSansSC-Regular.ttf', 'NotoSans', 'normal');
    doc.setFont('NotoSans'); // Use Noto Sans as the default font
    const margin = 10;
    const lineHeight = 10;
    const extraSpaceBetweenRows = 10; // Increased additional space between rows
    const dottedLineLength = 40; // Length of dotted line for missing words
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    let y = margin + 20;
  
    // Add title
    addTitle({ doc, title: title + " - Quiz", pageWidth, y });
    y += lineHeight * 2;
  
    // Add table headers
    addTableHeaders({ doc, sourceLanguage, targetLanguage, pageWidth, y });
    y += lineHeight;
  
    // Add a subtitle line under the table headers
    doc.setLineWidth(0.5);
    doc.setDrawColor(150); // A lighter color for the subtitle line
    doc.line(margin, y, pageWidth - margin, y);
    y += lineHeight + extraSpaceBetweenRows; // Extra space after subtitle line
  
    // Add quiz items with dotted lines and more separation
    doc.setFontSize(12);
    items.forEach((item, index) => {
      if (y + lineHeight > pageHeight - margin - 20) {
        addFooter({ doc, pageWidth, pageHeight });
        doc.addPage();

        // Reset styles after page break
        doc.setFontSize(12); // Reset font size
        doc.setTextColor(0); // Reset text color
        doc.setFillColor(255, 255, 255); // Reset fill color
        //doc.setFont('helvetica', 'normal'); // Reset font

        y = margin + 20 + lineHeight * 2;
        addTableHeaders({ doc, sourceLanguage, targetLanguage, pageWidth, y: y - lineHeight });
        doc.setFontSize(12); // Reset font size
        // Add a subtitle line under the table headers
        doc.setLineWidth(0.5);
        doc.setDrawColor(150); // A lighter color for the subtitle line
        doc.line(margin, y, pageWidth - margin, y);
        y += lineHeight + extraSpaceBetweenRows; // Adjust for new page
      }
  
      // Randomly remove one word (either source or target)
      const hideSource = Math.random() < 0.5;
      const sourceText = hideSource ? ' '.repeat(dottedLineLength / 2) : item.source;
      const targetText = hideSource ? item.target : ' '.repeat(dottedLineLength / 2);
  
      // Draw dotted line if word is hidden
      const adjustedY = y + 3;
      if (hideSource) {
        drawDottedLine(doc, (pageWidth / 4) - (dottedLineLength / 2), adjustedY, dottedLineLength);
      } else {
        drawDottedLine(doc, (3 * pageWidth / 4) - (dottedLineLength / 2), adjustedY, dottedLineLength);
      }
  
      const sourceX = (pageWidth / 4) - (doc.getTextWidth(sourceText) / 2);
      const targetX = (3 * pageWidth / 4) - (doc.getTextWidth(targetText) / 2);
      doc.text(sourceText, sourceX, y);
      doc.text(targetText, targetX, y);
  
      // Reset fill color for the next row
      doc.setFillColor(255, 255, 255); // Reset to white background
  
      y += lineHeight + extraSpaceBetweenRows;
    });
  
    // Add the footer on the last page
    addFooter({ doc, pageWidth, pageHeight });
  
    // Save the PDF
    doc.save(`${title} - Quiz.pdf`);

    // Log
    addExportDeckLog({ deckId: deckId, exportType: "pdf-quiz" });
  };

    const drawDottedLine = (doc, x, y, length) => {
        const lineWidth = 1;
        const spaceWidth = 1;
        const segmentCount = Math.floor(length / (lineWidth + spaceWidth));

        for (let i = 0; i < segmentCount; i++) {
        doc.line(x + i * (lineWidth + spaceWidth), y, x + i * (lineWidth + spaceWidth) + lineWidth, y);
        }
    };

  return { generateDeckListPdf, 
    generateDeckQuizPdf
   };
};

export default usePdfs;
