import {firebase, projectFunctions, projectFirestore} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

export default function useUserSettings() {

    let {currentUser} = useAuth();
    

    const fetchUserLanguagesFromUid = async (uid) => {
        let query = projectFirestore.collection('users').doc(uid).collection('private-data').doc();
    }

    const fetchPublicUserInfoFromUid = async (uid) => {
        let info = await projectFirestore.collection('users').doc(uid).get().then((snapshots)=>{
            return snapshots.data(); 
        });
        return info;
    }

    const fetchPublicUserInfoFromUsername = async (username, current_uid) => {
        //console.log("starting");
        // if the username is connected to the current user uid, we use a direct query. Otherwise we use a query for public profiles, in order to please the seecurity rules. This would be more efficient if the username was connected to the currentUser object (as displayName possibly?)
        // checking current uid
        // later: also add query to satisfy friendship relations
        let own_profile_bool = false;
        let results;
        //console.log("UID: ", current_uid);
        if (current_uid !== null){
            //console.log("Logged in!");
            let current_query = projectFirestore.collection('users').doc(current_uid);
            [own_profile_bool, results] = await current_query.get().then((snapshot)=> {
            //console.log("Snap: ", snapshot.data().username, username);
            if (snapshot.exists && snapshot.data().username === username){
                // looking at own profile
                return [true, snapshot];
            } else {
                return [false, null];
            }
        });
        }
        if (!own_profile_bool){
            let query = projectFirestore.collection('users').where('username', '==', username).where('public_profile', '==', true);
            results = await query.get().then((snapshots)=>{
                //console.log("SNAP: ", snapshots.docs[0].data());
                return snapshots.docs[0];
            });
        }

        let data = results.data();
        let profile_uid = results.id;
        data['uid'] = profile_uid;
        //add default values
        if (!('displayname' in data)){
            data['displayname'] = "";
        };
        ////console.log(data);
        return [data, own_profile_bool];
    }

    const getAccountInformationFromCurrentUser = async () => {
        let information = await projectFirestore.collection('users').doc(currentUser.uid).collection("private-data").doc("general").get().then((snapshot)=>{
            if (snapshot.exists){
                return snapshot.data();
            } else {
                return false;
            }
        });
        return information;
    }

    const addUidsToAllDecks = async () => {
        let users = await projectFirestore.collection('users').get().then((snapshots)=>{
            let user_list = [];
            for (const snapshot of snapshots.docs){
                user_list.push(snapshot.id);
            }
            return user_list;
        });
        
        // for each user, go into categories and get deck IDS
        for (const userID of users){
            let colRef = projectFirestore.collection('users').doc(userID).collection('private-data').doc('decks').collection('categories');
            let cat_list = await colRef.get().then((snapshots) => {
                let cat_list = [];
                for (const snapshot of snapshots.docs){
                    //console.log(snapshot, snapshot.id);
                    cat_list.push(snapshot.id);
                }
                //console.log(cat_list);
                return cat_list;
            });
            for (const cat of cat_list){
                let docRef = colRef.doc(cat);
                
                let deck_ids = await docRef.get().then((snapshots)=>{
                    let deck_ids = [];
                    try {
                        //console.log("docs: ", snapshots.data()['decks']['id']);
                        deck_ids = snapshots.data()['decks']['id'];
                    }
                    catch {
                    }
                    return deck_ids; 
                })

                for (const doc_id of deck_ids){
                    let docRef = projectFirestore.collection('decks').where('id', '==', doc_id);
                    await docRef.get().then((snapshots)=>{
                        //console.log("DEKC: ", snapshots.docs[0].id);
                        let deck_id = snapshots.docs[0].id;
                        let updateRef = projectFirestore.collection('decks').doc(deck_id);
                        updateRef.update({
                            'uid': userID
                        })
                    })
                }
            }
        }
    }

    const fetchPublicUserInfo = async () => {
        let info = await projectFirestore.collection('users').doc(currentUser.uid).get().then((snapshots)=>{
            return snapshots.data(); 
        });
        return info;
    }

    const fetchCurrentUserInfo = async () => {
        let info = await projectFirestore.collection('users').doc(currentUser.uid).get().then((snapshot)=>{
            if (snapshot.exists){
                return snapshot.data(); 
            } else {
                return null;
            }
        });
        return info;
    }

    const fetchRealtimePublicUserInfo = async (uid, setStateFunc) => {
        await projectFirestore.collection('users').doc(uid).onSnapshot((snapshots)=>{
            setStateFunc(snapshots.data()); 
        });
    }

    const checkIfUsernameExists = async (username) => {
        // excludes your own username
        //console.log("Checking ", username);
        let query = projectFirestore.collection('users').where('username','==', username).where(firebase.firestore.FieldPath.documentId(), '!=', currentUser.uid);
        //console.log("whaaat");
        let exists = await query.get().then((snapshot)=>{
            //console.log("WHHA");
            if (snapshot.empty){
                return false; 
            } else {
                return true;
            }
        }).catch((error)=>{
            //console.log("Error: ", error);
        });
        //console.log("Already exists: ", exists);
        return exists;
    }

    const checkIfUsernameAcceptable = (username) => {
        let result = /^[a-z0-9_]{4,18}$/.test(username);
        return result;
    }

    function generateRandomUnverifiedUsername(length) {
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789_';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    const generateRandomVerifiedUsername = async () => {
        let generatedUsername = await generateRandomUnverifiedUsername(15);
        //console.log("Generated: ", generatedUsername);
        let acceptable = await checkIfUsernameAcceptable(generatedUsername);
        //console.log("Acceptable ", acceptable);
        while (!acceptable){
            generatedUsername = await generateRandomUnverifiedUsername(15);
            acceptable = await checkIfUsernameAcceptable(generatedUsername);
        }
        if (acceptable){
            //console.log("Checking...");
            let exists = await checkIfUsernameExists(generatedUsername);
            //console.log("Exists: ", exists);
            if (exists){
                generateRandomVerifiedUsername();
            } else {
                return generatedUsername;
            }
        }
    }

    const updatePublicUserInfo = async (state) => {
        //updates public user info with state of every field (all fields not necessary)
        let success = true; 
        let errorField = null;
        let errorFields = [];
        let errorMessages = {};

        let usernameAcceptable = await checkIfUsernameAcceptable(state.username);
        state.username = state.username.toLowerCase();
        state.displayname = state.displayname.trim();
        //console.log("user: ", state.username);
        if (usernameAcceptable){
            let usernameExists = await checkIfUsernameExists(state.username);
            if (usernameExists){
                success = false;
                errorField = "username";
                errorFields.push(errorField);
                errorMessages[errorField] = "This username is already in use.";
            }

            if (success){
                await projectFirestore.collection('users').doc(currentUser.uid).update(state);
            }
        }
        else {
            success = false;
            errorField = "username";
            errorFields.push(errorField);
            errorMessages[errorField] = "The username needs to be between 4 and 18 valid characters (numbers, latin letters and underscore).";
        }

        return {
            success: success,
            errorFields: errorFields, 
            errorMessages: errorMessages
        };
    }

    const fetchUserActionsFromCurrentUser = async () => {
        let query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('actions');
        let actions = null;
        await query.get().then((querySnapshot)=>{
            //console.log(querySnapshot);
            if (!querySnapshot.exists){
                // create missing document
                actions = {'my_decks': {
                    'introduction': false
                    }  
                }
                projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('actions').set(actions);
            } else {
                actions = querySnapshot.data();
            }
        })
        return actions;
    }

    const setActionToTrueForCurrentUser = async (folder, name) => {
        let query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('actions');
        query.set({
            [`${folder}`]: {
                [`${name}`]: true
            }
        }, { merge: true });
    }

    const setTopLevelActionToTrueForCurrentUser = async (name) => {
        let query = projectFirestore.collection('users').doc(currentUser.uid).collection('private-data').doc('actions');
        await query.set({
            [`${name}`]: true
        }, { merge: true });
    }

    const createUserInDatabase = async (uid,name) => {
        let docRef = projectFirestore.collection('users').doc(uid);
        //let generatedUsername = await generateRandomVerifiedUsername();
        ////console.log("Generated username: ", generatedUsername);
        const success = await docRef.set({
            'username': null, 
            'displayname': '', 
            'friends_privacy': 'private',
            'languages_privacy': 'public',
            'points_privacy': 'private',
            'public_profile': false
        });
        //console.log("Success after creation: ", success);
        /*     
        .then(() => {
            docRef.collection('private-data').doc('general').set({
                'name': name, 
                'created_timestamp': timeStamp, 
                'last_updated_timestamp': timeStamp
            });
        })
        .then(()=>{
            docRef.collection('private-data').doc('languages').set({
                'ISO_639-1': []
            });
        })
        .then(() => {
            docRef.collection('private-data').doc('decks').set({});
        })
        .then(()=>{
            let success = true;
            return success
        }).catch((error)=>{
            //console.log("Error: ", error);
        });     
        */
        return true;    
    }

    const generateUniqueUsernameApi = async () => {
        const generateUsername =  projectFunctions.httpsCallable('generateUsername');
        const results = await generateUsername();
        return results['data'];
    }

    const addUsernameToCurrentUser = async () => {
        const addUsernameToCurrentUser =  projectFunctions.httpsCallable('addUsernameToCurrentUser');
        return addUsernameToCurrentUser();
    }

    const checkUsernameApi = async (username) => {
        //console.log("Checking ", username);
        const checkUsername =  projectFunctions.httpsCallable('checkUsername');
        const results = await checkUsername({'username': username});
        //console.log("Returned: ", results['data']);
        return results['data'];
    }

    const saveProfileSettingsApi = async (state) => {
        const saveProfileSettings =  projectFunctions.httpsCallable('saveProfileSettings');
        const results = await saveProfileSettings({'state': state});
        return results['data'];
    }

    const updateAccountNameForCurrentUserApi = async (name) => {
        const updateAccountName =  projectFunctions.httpsCallable('updateAccountName');
        try {
            await updateAccountName({'name': name});
            return true;
        } catch {
            return false;
        }
    }

    const updateAccountNameForCurrentUser = async (name) => {
        if (name.length > 50){
            return false;
        }
        projectFirestore.collection('users').doc(currentUser.uid).collection("private-data").doc("general").set({
            'name': name
        }, {merge:true}).catch((error)=> {
            console.log(error);
            return false;
        });
        return true;
    }

    const verifyThatCurrentUserExistsInDatabase = async () => {
        const doCheck = async () => {
            let result = await projectFirestore.collection('users').doc(currentUser.uid).get().then(snapshot=>{
                if (snapshot.exists === true){
                    return true;
                } else  {
                    return false;
                }
            }).catch(error=>{
                return false;
            });
            return result;
        }
        let success = await doCheck();
        // Multiple tries
        if (success){
            return success;
        }
        if (!success){
            await new Promise(resolve => setTimeout(resolve, 2000));
            success = await doCheck();
            if (success){
                return success;
            }
        } 
        if (!success){
            await new Promise(resolve => setTimeout(resolve, 2000));
            success = await doCheck();
            if (success){
                return success;
            }
        } 
        if (!success){
            await new Promise(resolve => setTimeout(resolve, 3000));
            success = await doCheck();
            if (success){
                return success;
            }
        } 
        if (!success){
            await new Promise(resolve => setTimeout(resolve, 5000));
            success = await doCheck();
            if (success){
                return success;
            }
        } 
        if (!success){
            await new Promise(resolve => setTimeout(resolve, 10000));
            success = await doCheck();
            if (success){
                return success;
            }
        } 
        if (!success){
            await new Promise(resolve => setTimeout(resolve, 5000));
            success = await doCheck();
            if (success){
                return success;
            }
            return false;
        } 
    }

    const createUserDatabaseEntries = async () => {
        const createUserDatabaseEntries =  projectFunctions.httpsCallable('createUserDatabaseEntries');
        return createUserDatabaseEntries();
    }

    const updateUserDeckSettingsApi = async (settings) => {
        let f = projectFunctions.httpsCallable('updateUserDeckSettings');
        return f({'settings': settings});
    }

    const fetchCurrentUserSettings = async () => {
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("settings");
        let data = await query.get().then((snap)=>{
            if (snap.exists){
                return snap.data();
            } else {
                return null;
            }
        }).catch(err=>{
            return null;
        });
        return data;
    }

    const updateLibraryViewSetting = async ({view}) => {
        if (view === "grid" || view === "tree"){
            let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("settings");
            await query.set({
                'library_view': view
            }, {merge: true});
        }
    }

    const updateUserSettings = async ({settings}) => {
        let newSettings = {};
        if (settings.hasOwnProperty("autoplayGameAudio")){
            newSettings['autoplayGameAudio'] = settings['autoplayGameAudio'];
        }
        if (Object.keys(newSettings).length > 0){
            let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("settings");
            await query.update(newSettings);
        }
    }

    const getUserSettings = async () => {
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("settings");
        let data = await query.get().then((snap)=>{
            if (snap.exists){
                let d = snap.data();
                d['doc_id'] = snap.id;
                return d;
            } else {
                return false;
            }
        }).catch(err=>{
            return false;
        });
        return data;
    }

    const readLibraryViewSetting = async () => {
        let query = projectFirestore.collection("users").doc(currentUser.uid).collection("private-data").doc("settings");
        let data = await query.get().then((snap)=>{
            if (snap.exists){
                return snap.data();
            } else {
                return null;
            }
        }).catch(err=>{
            return null;
        });
        let view = data.hasOwnProperty("library_view") ? data['library_view'] : false;
        return view;
    }

    return {
        addUidsToAllDecks, 
        fetchCurrentUserSettings,
        updateUserDeckSettingsApi,
        setActionToTrueForCurrentUser,
        fetchPublicUserInfo, 
        updatePublicUserInfo, 
        generateRandomVerifiedUsername, 
        fetchPublicUserInfoFromUid, 
        fetchRealtimePublicUserInfo, 
        fetchPublicUserInfoFromUsername, 
        fetchUserLanguagesFromUid, 
        fetchCurrentUserInfo,
        fetchUserActionsFromCurrentUser, 
        createUserInDatabase, 
        generateUniqueUsernameApi, 
        checkUsernameApi, 
        checkIfUsernameAcceptable, 
        saveProfileSettingsApi, 
        updateAccountNameForCurrentUserApi, 
        updateAccountNameForCurrentUser, 
        setTopLevelActionToTrueForCurrentUser, 
        verifyThatCurrentUserExistsInDatabase, 
        createUserDatabaseEntries, 
        addUsernameToCurrentUser, 
        getAccountInformationFromCurrentUser, 
        updateLibraryViewSetting, 
        readLibraryViewSetting,
        updateUserSettings,
        getUserSettings
    };

}