import React from "react";
import LanguageTools_zh from "./zh/LanguageTools_zh";
import { Link } from "react-router-dom";


const SpecificLanguageTools = (props) => {
    const {language, allLanguages} = props;

    const components = {
        "zh": <LanguageTools_zh />,
    }
    return (
        <>
            {components.hasOwnProperty(language) ? components[language]
            :
                <div className="flex flex-col gap-4">
                    <div className="font-semibold text-lg lg:text-lg text-center">Useful tools & resources for {allLanguages !== null && language !== null && allLanguages[0][language]["name"]["en"]}</div>
                    <div className="flex flex-col gap-3 place-items-center text-sm">
                        <Link to={"/decks?target="+language}>Decks from other users in {allLanguages !== null && language !== null && allLanguages[0][language]["name"]["en"]}</Link>
                        <Link to={"/texts?target="+language}>AI texts in {allLanguages !== null && language !== null && allLanguages[0][language]["name"]["en"]}</Link>
                        <Link to={"/watch"}>Study YouTube videos</Link>
                    </div>
                </div>
            }
        </>
    );
};

export default SpecificLanguageTools;