import React, {useState, useEffect} from 'react'
import useCourses from '../../hooks/useCourses';
import { useLanguagesContext } from '../../contexts/LanguagesContext';
import { Link } from 'react-router-dom';
import FeedbackButton from '../general/FeedbackButton';

const YalangoContentForLanguage = () => {
  let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions} = useLanguagesContext();
  const [courses, setCourses] = useState(null);
  const {fetchYalangoCoursesForLanguage} = useCourses();

  useEffect(()=>{
    const fetchData = async () => {
      console.log("Fetching data")
      let results = await fetchYalangoCoursesForLanguage({targetLanguage: globalSelectedTargetLanguage});
      setCourses(results);
      console.log("Results", results)
    };

    fetchData();
  },[globalSelectedTargetLanguage]);

  console.log("https://firebasestorage.googleapis.com/v0/b/soothing-languages.appspot.com/o"+"/images/courses/thumbnails/thumbnails_200x200/shutterstock_2119782773_200x200.webp".replaceAll("/", "%2F") + "?alt=media")

  return (
        <>

        <div className="flex flex-col gap-4">
              <div className="text-lg font-semibold text-center">Official {allLanguages !== null && globalSelectedTargetLanguage !== null && allLanguages[0][globalSelectedTargetLanguage]["name"]["en"]} courses from Yalango</div>
              <div>
            <div className="lg:w-1/2 mx-auto flex flex-col gap-4">
                <div className="text-sm text-center flex flex-col place-items-center gap-4">
                  {courses !== null && courses.length === 0 && 
                    <>
                      There are no official courses for this language yet.
                      <FeedbackButton text="Request course" />
                    </>
                  }
                </div>
            
                <div>
                  {courses !== null && courses.length > 0 && 
                    <>
                      <div className="flex flex-col md:flex-row gap-6 place-items-center justify-center">
                        {courses.map((course, index) => (
                          <Link key={index} className="no-underline w-64 h-64 flex flex-col place-items-center justify-center" to={`/course/${course.course_id}`}>
                            <div className="w-full h-full flex flex-col gap-3 place-items-center justify-center">
                              <div className="rounded-2xl shadow-xl overflow-hidden flex flex-col justify-center">
                                {course.thumbnail_200x200 !== null &&
                                  <img src={"https://firebasestorage.googleapis.com/v0/b/soothing-languages.appspot.com/o/"+course.thumbnail_200x200.replaceAll("/", "%2F") + "?alt=media"} alt={course.name} />
                                }
                              </div>
                              <div className="text-2xl font-semibold muted-text">
                                {course.name}
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </>
                  }
                </div>
            </div>
            </div>
          </div>
        </>
  )
}

export default YalangoContentForLanguage;