import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect} from 'react';
import useCourses from '../../../hooks/useCourses';
import { Link, useParams } from 'react-router-dom';
import GeneralErrorPage from '../../../components/errors/GeneralErrorPage';
import DeckInCourseCard from '../../../components/courses/DeckInCourseCard';
import { useLanguagesContext } from '../../../contexts/LanguagesContext';

const CourseHome = () => {
    let { courseId } = useParams();
    const {allLanguages} = useLanguagesContext();
    const {fetchCourseFromId, fetchCourseLessons, getDefaultThumbnailImage} = useCourses();

    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [defaultImage, setDefaultImage] = useState(null);
    const [lessons, setLessons] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            console.log("Fetching course from id: ", courseId)
            const results = await fetchCourseFromId({courseId});
            console.log("Course: ", results)
            if (results !== null && results.hasOwnProperty("lesson_ids")){
                let l = results.lesson_ids;
                if (l !== null && l.length > 0){
                    let lesResults = await fetchCourseLessons({lessonIds: l, course: results});
                    console.log("Lessons: ", lesResults)
                    setLessons(lesResults);
                }
            }
            let def = getDefaultThumbnailImage();
            setDefaultImage(def);
            setCourse(results);
            setLoading(false);
        }
        if (courseId === null || courseId === undefined || fetchCourseFromId === undefined){return null}
        fetchData();
    }, [courseId, fetchCourseFromId]);

    return (
        <div>
            {loading &&
                <>
                    <div className="h-screen w-screen fixed flex flex-col place-items-center justify-center text-4xl">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                </>
            }
            {!loading &&
                <>
                    {course === null &&
                        <div className="h-screen">
                            <GeneralErrorPage errorMessage={"Please verify that the course ID is correct and try again, or browse all of our courses instead."} errorHeadline={"Course not found"} goBackLink={"/dashboard"} goBackText={"Browse all courses"} />
                        </div>
                    }
                    {course !== null &&
                        <div>
                            <h1>{course.name}</h1>
                            <div className="text-center">
                                {allLanguages !== null && course.author !== null && course.author !== undefined && <>A course created by {course.author} for learners of {allLanguages[0][course["target_ISO_639-1"]]["name"]["en"]}. </>}
                            </div>
                            {lessons !== null &&
                                <>
                                    <div className="grid grid-cols-auto place-items-center gap-6">
                                        {lessons.map((lesson, index) => (
                                            lesson.content_id !== null && lesson.content_type !== null &&
                                                <div key={index}>
                                                    <Link className="no-underline w-64 h-64 flex flex-col place-items-center justify-center"  to={`/course/${courseId}/lesson/${lesson.lesson_id}`}>
                                                        <div className="w-full h-full flex flex-col gap-3 p-6  place-items-center justify-center">
                                                            <div className="rounded-2xl shadow-xl h-full w-full overflow-hidden flex flex-col justify-center relative">
                                                                
                                                                <img src={lesson?.full_thumbnail_path_200x200 ? lesson.full_thumbnail_path_200x200 : defaultImage} className="w-full grayscale" alt={lesson.lesson_name} />
                                                                <div className="h-full w-full bg-yellow-800 opacity-60 absolute top-0 left-0 "></div>
                                                                <div className="absolute -left-1 -top-1 p-2 bg-white  rounded-br-xl shadow-xl text-lg font-bold">
                                                                    {index+1}
                                                                </div>
                                                            </div>
                                                            <div className="text-2xl font-semibold muted-text">
                                                                {lesson.lesson_name}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                        ))}
                                       
                                    </div>
                                </>
                            }
                             {lessons === null || lessons.length === 0 &&
                                <>
                                    <div className="text-center">
                                        This course has no lessons yet. 
                                    </div>
                                </>
                            }
                        </div>
                    }
                </>
            }
        </div>
    );
    };

export default CourseHome;