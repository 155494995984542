import React, {useEffect, useState} from 'react';
import { useAuth } from "../../contexts/AuthContext";
import Button from '../../components/general/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/pro-solid-svg-icons';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import useLogs from '../../hooks/useLogs';

const EmailVerification = (props) => {

    const {emailIsSent=false, hideDashboardLink=false, onSuccess=null} = props;

    const {checkIfVerifiedEmail, currentUser, verifyEmail} = useAuth();
    const [emailIsVerified, setEmailIsVerified] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [verificationEmailSent, setVerificationEmailSent] = useState(emailIsSent);
    const [notYetClicked, setNotYetClicked] = useState(false);
    const [error, setError] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const {saveErrorLog} = useLogs();

    useEffect(()=>{
        const check = async () => {
            let results = await checkIfVerifiedEmail();
            setEmailIsVerified(results);
            if (results === true){
                if (onSuccess !== null){
                    onSuccess();
                }
            }
            setInitialLoading(false);
        }
        check();
    },[currentUser, checkIfVerifiedEmail]);

    const verifyEmailClick = async () => {
        setButtonLoading(true);
        setError(false);
        await verifyEmail(currentUser).catch(err=>{
            console.log(err);
            setError(true);
            saveErrorLog({errorObject: err, functionName: "verifyEmailClick"});
        });
        setVerificationEmailSent(true);
        setButtonLoading(false);
    }

    const reloadEmailVerification = async () => {
        setButtonLoading(true);
        setNotYetClicked(false);
        setError(false);
        let results = await checkIfVerifiedEmail();
        setEmailIsVerified(results);
        if (results === true){
            if (onSuccess !== null){
                onSuccess();
            }
        }
        if (!results){
            setNotYetClicked(true);
        }
        setButtonLoading(false);
    }
    return (
    <>  
        {initialLoading ? 
            <>
                <div className="flex flex-row justify-center">
                    <FontAwesomeIcon className={buttonLoading ? "fa-pulse " : " "} icon={faSpinner} />
                </div>
            </>
        :
            <div>
                {emailIsVerified === false &&
                    <>
                        <div className="flex flex-col place-items-center gap-6">
                            <div className="flex flex-row gap-2">
                                <div className="text-burnt-sienna">
                                    <FontAwesomeIcon icon={faX} />
                                </div>
                                <div className="text-gray-600">
                                    Your email is not yet verified.
                                </div>
                            </div>
                            <div>
                                {verificationEmailSent ? 
                                    <>
                                        <div className="flex flex-col place-items-center gap-3">
                                            {!notYetClicked ?
                                                <>
                                                    <div>
                                                        Please check your inbox and click on the confirmation link we've sent you. 
                                                       
                                                    </div>
                                                    <div>
                                                        Didn't receive it? <span className="underline cursor-pointer" onClick={verifyEmailClick}>Click here</span> to send it again.
                                                    </div>
                                                </>
                                            :
                                                <div>
                                                    Your email has not yet been verified. Please click on the link in the email, and make sure to check your spam folder if you don't find our email.
                                                </div>
                                            }
                                            <div>
                                                <Button onClick={reloadEmailVerification} text={buttonLoading ? <FontAwesomeIcon className={buttonLoading ? "fa-pulse " : " "} icon={faSpinner} /> : "I have clicked on the link"} color="charcoal" />
                                            </div>
                                        </div>
                                    </>
                                :
                                    <>
                                        <Button onClick={verifyEmailClick} text={buttonLoading ? <FontAwesomeIcon className={buttonLoading ? "fa-pulse " : " "} icon={faSpinner} /> : "Verify now"} color="green"  />
                                    </>
                                }
                            </div>
                            <div>
                                {error === true &&
                                    <>
                                        Something went wrong. Please contact Yalango support.
                                    </>
                                }
                            </div>
                        </div>
                    </>
                }
                {emailIsVerified === true && 
                    <>
                        <div className="flex flex-row gap-2 justify-center">
                            <div className="text-persian-green">
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <div className="text-gray-600">
                                Your email is successfully verified.
                            </div>
                        </div>
                        {hideDashboardLink === false &&
                            <div className="no-underline flex flex-row justify-center">
                                <Link to="/dashboard" className="no-underline">
                                    <Button text="Go to your dashboard" color="green" />
                                </Link>
                            </div>
                        }
                    </>
                }
            </div>
        }
    </>
  )
}

export default EmailVerification