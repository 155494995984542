import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useLanguagesContext } from '../../contexts/LanguagesContext';
import CountryFlag from '../../components/languages/CountryFlag';
import Button from '../general/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCardsBlank, faCoconut, faInfo } from '@fortawesome/pro-solid-svg-icons';

function CourseLessonIntro(props) {
    const {lessonTitle, deck, deckCards, contentType, startLessonClick, course} = props;
    console.log(course)
    let {allLanguages} = useLanguagesContext();
    const [showWordList, setShowWordList] = useState(false);

    const toggleWordList = () => {
        setShowWordList(!showWordList);
    }   

    return (
        <div className="dashboard flex-col place-items-center justify-center flex gap-12">
            <h1>Lesson overview</h1>
            <div className="flex flex-row gap-12 text-xs">
                <div className="muted-text flex flex-col gap-3">
                    <FontAwesomeIcon icon={faInfo} size="3x" />
                    <div>
                        {contentType === "deck" && deck !== null && deckCards !== null &&
                            <>
                                {lessonTitle}
                            </>
                        }
                    </div>
                </div>
                <div className="muted-text flex flex-col gap-3">
                    <FontAwesomeIcon icon={faCardsBlank} size="3x" />
                    <div>
                        {contentType === "deck" && deck !== null && deckCards !== null &&
                            <>
                                {deckCards.length} items
                            </>
                        }
                    </div>
                </div>
                <div className="muted-text flex flex-col gap-3">
                    <FontAwesomeIcon icon={faBook} size="3x" />
                    <div>
                        {contentType === "deck" && deck !== null && deckCards !== null &&
                            <>
                                <Link to={`/decks/${deck.id}`}>Connected deck</Link>
                            </>
                        }
                    </div>
                </div>
            </div>
            {contentType === "deck" && deck !== null && deckCards !== null &&
                <>
                    <div className="flex flex-col lg:flex-row lg:flex-row-reverse place-items-center gap-3">
                        <div className="">
                            <Button onClick={startLessonClick} text={"Start lesson"} color="green" size="normal" />
                        </div>
                        {course !== null &&
                            <>
                                <Link to={`/course/${course.course_id}`} className="no-underline">
                                    <div>
                                        <Button onClick={startLessonClick} text={"Go back"} color="charcoal" size="normal"  />
                                    </div>
                                </Link>
                            </>
                        }   
                    </div>
                    <div className="sm:w-5/6 mx-auto muted-text text-sm  p-4  cursor-pointer font-semibold w-full text-center" onClick={toggleWordList}>
                    {showWordList ?
                         <div className="flex flex-row gap-3 justify-center place-items-center">
                            Hide word list <span className="opacity-60 text-xs">{deckCards.length} </span>
                        </div>
                        :
                        <div className="flex flex-row gap-3 justify-center place-items-center">
                            Show words you will study in this lesson <span className="opacity-60 text-xs">{deckCards.length} </span>
                        </div>
                    }
                    </div>
                    {showWordList &&
                        <div className="w-full h-96 overflow-y-auto sm:w-5/6 xl:w-1/2 mx-auto">
                            <div className=" w-full">
                                <table className="m-auto text-center w-full table-auto ">
                                    <thead>  
                                        <tr className="text-center  muted-text font-bold">
                                            {(allLanguages !== null) &&
                                                <>
                                                    <th className=" w-3/6  border px-8 py-4">
                                                        <div className="flex flex-row place-items-center gap-4 justify-center ">
                                                            <div className="rounded-xl shadow-lg overflow-hidden">
                                                                <CountryFlag flagName={allLanguages[0][deck['target_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][deck['target_ISO_639-1']]['country_flag']} size="2em" /> 
                                                            </div>
                                                            <div>
                                                                {allLanguages[0][deck['target_ISO_639-1']]['name']['en']} {deck.hasOwnProperty("target_script") && deck['target_script'] !== null && deck['target_script'] !== "" && <>({deck['target_script']})</>}
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className="  w-3/6  border  px-8 py-4">
                                                    <div className="flex flex-row place-items-center gap-4 justify-center ">
                                                            <div className="rounded-xl shadow-lg overflow-hidden">
                                                                <CountryFlag flagName={allLanguages[0][deck['source_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][deck['source_ISO_639-1']]['country_flag']} size="2em" /> 
                                                            </div>
                                                            <div>
                                                                {allLanguages[0][deck['source_ISO_639-1']]['name']['en']} {deck.hasOwnProperty("source_script") && deck['source_script'] !== null && deck['source_script'] !== "" && <>({deck['source_script']})</>}
                                                            </div>
                                                        </div>
                                                    </th>  
                                                </>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {deckCards.map((item, index)=>(
                                        <tr className="">
                                            <td className=" border py-2 sm:px-8 sm:py-2 ">
                                                <div className="bg-white rounded-xl py-4">
                                                    <div className="flex gap-3 flex-row justify-center">
                                                        <div>
                                                            {item.target}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className=" border py-2 sm:px-8 sm:py-2 ">
                                                <div className="bg-white rounded-xl py-4">
                                                    <div className="flex gap-3 flex-row justify-center">
                                                        <div>
                                                            {item.source}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default CourseLessonIntro;