import React, {useEffect, useState, useRef} from 'react';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import useVocabulary from '../../hooks/useVocabulary';
import CountryFlag from '../../components/languages/CountryFlag';
import Helmet from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import {faCalendarDays} from '@fortawesome/pro-solid-svg-icons';
import {faDialOff} from '@fortawesome/pro-solid-svg-icons';
import {faDialMin} from '@fortawesome/pro-solid-svg-icons';
import {faDialLow} from '@fortawesome/pro-solid-svg-icons';
import {faDialMedLow} from '@fortawesome/pro-solid-svg-icons';
import {faDialMed} from '@fortawesome/pro-solid-svg-icons';
import {faDial} from '@fortawesome/pro-solid-svg-icons';
import {faDialHigh} from '@fortawesome/pro-solid-svg-icons';
import {faDialMax} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTableColumns} from '@fortawesome/pro-duotone-svg-icons';
import SortByButton from '../../components/general/sorting/SortByButton';
import { useNavigate } from 'react-router-dom';
import ProgressMenu from '../../components/menus/ProgressMenu';

const MyVocabularySpacedRepetition = () => {
    const {refreshSpacedRepetitionQueue, 
        mostUrgentNextReviewItems
    } = useUserStatisticsContext();

    let {fetchItemsBasedOnCurrentIndexFromSpacedRepetitionVocabulary, 
        fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabularyApi} = useVocabulary();

    const [itemsPerPage] = useState(25);
    const [dbLoading, setDbLoading] = useState(true);

    const [page, setPage] = useState("queue");
    const [showInformation, setShowInformation] = useState(false);

    const [specificIndexItems, setSpecificIndexItems] = useState(null);

    const { globalSelectedTargetLanguage, 
        allLanguages } = useLanguagesContext();

    let firstTimeRefreshingData = useRef(true);
    const [levels, setLevels] = useState([0,1,2,3,4,5,6,7,8]);
    const filterOptions = [{value: "queue", label: "Next in queue"}, ...levels.map((level)=>({value: level, label: "Level "+level}))];
    const [filterValue, setFilterValue] = useState(filterOptions[0]);

    const changeSortByValue = (option) => {
        setFilterValue(option);
        levelClick(option.value);
    }

    useEffect(()=>{
        if (globalSelectedTargetLanguage !== null){
            firstTimeRefreshingData.current = true;
        }
    },[globalSelectedTargetLanguage]);
    
    useEffect(()=>{
        const fetchData = async () => {
            firstTimeRefreshingData.current = false;
            setDbLoading(true);
            console.log("Fetching data...", Math.random());
            await refreshSpacedRepetitionQueue(itemsPerPage);
            setDbLoading(false);
        }

        if (globalSelectedTargetLanguage === null){
            return 
        }
        if (firstTimeRefreshingData.current === false){
            //setDbLoading(false);
            return 
        }
        if (page === "queue"){
            fetchData();
        }
    },[mostUrgentNextReviewItems, page, firstTimeRefreshingData, globalSelectedTargetLanguage, refreshSpacedRepetitionQueue, itemsPerPage]);


    const fetchItemsFromLevels = async (index) => {
        setDbLoading(true);
        setSpecificIndexItems(null);
        let r = await fetchItemsBasedOnCurrentIndexFromSpacedRepetitionVocabulary(globalSelectedTargetLanguage, itemsPerPage, index);
        setSpecificIndexItems(r);
        setDbLoading(false);
    }

    const levelClick = (level) => {
        setPage(level);
        if (level !== "queue"){
            fetchItemsFromLevels(level);
        }
    }



    return (
        <>
             <Helmet>
                <title>Spaced Repetition</title>
                <meta name="description" content={"Spaced repetition on Yalango."} />
            </Helmet>
            <h1>My progress</h1>
            <ProgressMenu tabValue={"spaced-repetition"} />
            <div className="flex flex-col gap-4 place-items-center">
                <div className="hidden text-sm text-charcoal cursor-pointer" onClick={()=>setShowInformation(!showInformation)}>
                    {showInformation ? "Hide information" : "Show information"}
                </div>
                {showInformation &&
                    <div className="w-full flex flex-col gap-4 sm:w-3/4 mx-auto text-sm text-center">
                        Yalango uses spaced repetition to optimize your study sessions. Below you can see the next {itemsPerPage} items coming up in your review queue. Yalango shows you your vocabulary item 1, 2, 4, 8, 16, 32, 64 and 128 days after you've started practicing it. If you fail at one of the intervals, the word will go down one level. If you complete all the levels without failing, Yalango considers that you know the word well enough and will not show it again in your study sessions (unless you explicitly choose a deck containing that word).
                        <div>
                            The games and exercises are adapted according to the current level of each item. The deck games are shown below.
                        </div>
                        <ul>
                            <li>
                                Level 0: quiz from target to source
                            </li>
                            <li>
                                Levels 1-3: either quiz or flashcard from target to source
                            </li>
                            <li>
                                Level 4-6: either input or flashcard from source to target
                            </li>
                            <li>
                                Level 7-8: input from source to target
                            </li>
                        </ul>
                        <div>
                            Click on the buttons below to see which words in your vocabulary are currently in the different levels of the memorization journey.
                        </div>
                    </div>
                }
                <div className="place-self-start flex flex-row justify-end sm:w-3/4 m-auto w-full">
                    <div>
                        <SortByButton textSize={"sm"} onChange={changeSortByValue} value={filterValue} options={filterOptions} />
                    </div>
                </div>
                <div className="hidden  max-h-48 overflow-y-auto w-full pb-12 px-8 justify-center gap-2 ">
                    <div onClick={()=>levelClick("queue")}  className={(page === "queue" ? "bg-charcoal text-white " : " bg-white text-charcoal ")+ " p-2 cursor-pointer rounded-lg shadow-lg "}>
                        Queue
                    </div>
                    {levels.map((level, levelIndex)=>(
                        <>
                            <div onClick={()=>levelClick(level)} className={(page === level ? "bg-charcoal text-white " : " bg-white text-charcoal ")+ " p-2 cursor-pointer rounded-lg shadow-lg "} key={"button_"+levelIndex}>
                                Level {level}
                            </div>
                        </>
                    ))}
                </div>
                <div className=" flex w-full flex-col gap-4 justify-center place-items-center">
                    {dbLoading && [...Array(itemsPerPage)].map((n, index)=>(
                        <>
                            <div key={"skeleton_"+index} className="w-full sm:w-3/4 h-full">
                                <Skeleton count={1} height={150} />
                            </div>
                        </>
                    ))}
                    {page === "queue" ?
                        <>
                            {(mostUrgentNextReviewItems !== null && !dbLoading && allLanguages !== null && globalSelectedTargetLanguage !== null && mostUrgentNextReviewItems.hasOwnProperty(globalSelectedTargetLanguage)) &&
                                <>
                                    {mostUrgentNextReviewItems[globalSelectedTargetLanguage].map((item, itemIndex)=>(
                                        <>
                                            <Item globalSelectedTargetLanguage={globalSelectedTargetLanguage} dbLoading={dbLoading} index={itemIndex} allLanguages={allLanguages} item={item} /> 
                                        </>
                                    ))}
                                </>
                            }
                        </>
                    :
                        <>
                            {specificIndexItems !== null && specificIndexItems.map((item, itemIndex)=>(
                                <>
                                    <Item globalSelectedTargetLanguage={globalSelectedTargetLanguage} dbLoading={dbLoading} index={itemIndex} allLanguages={allLanguages} item={item} /> 
                                </>
                            ))}
                            {specificIndexItems !== null && specificIndexItems.length === 0 &&
                                <>
                                    No items found in level {page}.
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

const Item = (props) => {
    const {index, dbLoading, allLanguages, item, globalSelectedTargetLanguage} = {...props};
    console.log(item)
    //const [reviewDate, setReviewDate] = useState((item.hasOwnProperty('spaced_repetition_next_review') && item['spaced_repetition_next_review'] !== null) ? item['spaced_repetition_next_review'].toDate() : null)
    const [numberOfDaysToReview, setNumberOfDaysToReview] = useState(null);
    const [reviewedToday, setReviewedToday] = useState(false);
    const [loading, setLoading] = useState(true);

    const [cycleIcons, setCycleIcons] = useState({
        0: faDialOff,
        1: faDialMin, 
        2: faDialLow, 
        3: faDialMedLow, 
        4: faDialMed,
        5: faDial,
        6: faDialHigh,
        7: faDialMax,
        8: faDialMax
    });

    useEffect(()=>{
        if (item.hasOwnProperty('spaced_repetition_next_review') && item['spaced_repetition_next_review'] !== null){
            let today = new Date();
            today.setHours(0,0,0,0);
            console.log("Next: ", item['spaced_repetition_next_review']);
            let reviewDate = null;
            try {
                reviewDate = item['spaced_repetition_next_review'].toDate();
            } catch {
                reviewDate = new Date(item['spaced_repetition_next_review']["_seconds"] * 1000 + item['spaced_repetition_next_review']["_nanoseconds"]/1000000);
            }
            reviewDate.setHours(0,0,0,0);
            let milliSecondsOneDay = 1000*60*60*24;
            let difference = Math.round((reviewDate - today)/milliSecondsOneDay);
            console.log("Difference: ", difference, today.getDate(), reviewDate.getDate());
            setNumberOfDaysToReview(difference);

            if (item.hasOwnProperty('spaced_repetition_last_correct_timestamp') && item['spaced_repetition_last_correct_timestamp'] !== null){
                let lastCorrect = null;
                try {
                    lastCorrect = item['spaced_repetition_last_correct_timestamp'].toDate();
                }
                catch {
                    lastCorrect = new Date(item['spaced_repetition_last_correct_timestamp']["_seconds"] * 1000 + item['spaced_repetition_last_correct_timestamp']["_nanoseconds"]/1000000);
                }
                lastCorrect.setHours(0,0,0,0);
                console.log(lastCorrect, reviewDate);
                if (lastCorrect - reviewDate === 0){
                    setReviewedToday(true);
                }
            } else {
                setReviewedToday(false);
            }

        } else {

        }

        setLoading(false);
    },[item, props]);

/*     useEffect(()=>{
        if ((item.hasOwnProperty('spaced_repetition_next_review') && item['spaced_repetition_next_review'] !== null)){
            setReviewDate(item['spaced_repetition_next_review'].toDate());
        } else {
            setReviewDate(null);
        }
    },[props, item]); */
    console.log(item, numberOfDaysToReview);
    const navigate = useNavigate();

    const vocabularyItemClick = () => {
        //<Link key={"link_"+index} to={"/my-vocabulary/" + globalSelectedTargetLanguage + "/item/"+vocabulary[index]?.id}
        navigate("/my-vocabulary/"+globalSelectedTargetLanguage+"/item/"+item.id);
    }

    return (
        <>
            {!loading &&
            <div key={"item_"+index} onClick={vocabularyItemClick} className={dbLoading ? "opacity-30 " : "text-gray-500 relative bg-white  hover:text-gray-700 hover:shadow-xl font-bold shadow-lg rounded-xl px-4 py-8  text-center  cursor-pointer w-full sm:w-3/4 "}>
                <div className="flex flex-col mb-3 sm:mb-0 gap-4 md:flex-row justify-between place-items-center">
                    <div className="absolute p-2 left-2 top-1">
                    </div>
                    {item.type === "deck" &&
                        <div className="md:pl-20 flex flex-col place-items-start  gap-6 justify-center">
                            <div className="flex flex-row place-items-center gap-3">
                                <div className="w-8 rounded-lg shadow-xl overflow-hidden">
                                    <CountryFlag flagName={allLanguages[0][item['target_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][item['target_ISO_639-1']]['country_flag']} />
                                </div>
                                <div className="whitespace-nowrap">
                                {dbLoading ? <Skeleton count={1} height={20} /> : <>{item.target}</>}
                                </div>
                            </div>
                            <div className="flex flex-row place-items-center gap-2">
                                <div className="w-8 rounded-lg shadow-xl overflow-hidden">
                                {(item['source_ISO_639-1'] !== undefined && item['source_ISO_639-1'] !== "" && item['source_ISO_639-1'] !== null ) &&
                                    <>
                                    <CountryFlag flagName={allLanguages[0][item['source_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][item['source_ISO_639-1']]['country_flag']} />
                                    </>
                                }
                                </div>
                                <div className="whitespace-nowrap">
                                    {dbLoading ? <Skeleton count={1} height={20} /> : <>{item.source}</>}
                                </div>
                            </div>
                            
                        </div>
                    }
                    {item.type === "table-row" &&
                        <>
                            <div className="flex flex-row justify-start p-2 w-full overflow-auto gap-2 text-base">
                                <div className="text-beautiful-blue">
                                    <FontAwesomeIcon icon={faTableColumns} />
                                </div>
                                
                                {item.hasOwnProperty('row_values') && Object.entries(item.row_values).map((key, index)=>(
                                    <>
                                        <div key={"col_"+index}>
                                            {key[1]}
                                        </div>
                                    </>
                                    ))}
                            </div>
                        </>
                    }
                    <div className="sm:mt-0 mt-2 flex w-full flex-row justify-between sm:place-items-center sm:flex-col gap-6 sm:gap-2 text-xs">
                        <div className="w-full flex flex-col gap-2 place-items-end">
                            <div className="flex flex-row  justify-center place-items-center sm:justify-end w-full gap-2">
                                <div className={numberOfDaysToReview > 0 || numberOfDaysToReview === null || reviewedToday ? "text-persian-green" : "text-burnt-sienna"}>
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                </div>
                                <div className="font-bold">
                                    {dbLoading ? <Skeleton count={1} height={20} /> : 
                                        <>
                                            {(numberOfDaysToReview !== null) ? 
                                                <>
                                                    {numberOfDaysToReview === 0 &&
                                                        <>
                                                            {reviewedToday ? 
                                                                <>
                                                                    Reviewed today
                                                                </>
                                                            :
                                                                <>
                                                                    Review today
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    {numberOfDaysToReview > 0 &&
                                                        <>Review in {numberOfDaysToReview} {numberOfDaysToReview === 1 ? "day" : "days"}</>
                                                    }
                                                    {numberOfDaysToReview < 0 &&
                                                        <>Planned review was {Math.abs(numberOfDaysToReview)} {Math.abs(numberOfDaysToReview) === 1 ? "day" : "days"} ago</>
                                                    }
                                                    
                                                </>
                                            :
                                                <>
                                                    <div className="italic ">
                                                        Not started
                                                    </div>
                                                </> 
                                            }
                                        </>
                                    } 
                                </div>
                            </div>
                            <div className="flex flex-row justify-center sm:justify-end w-full gap-2">
                                <div className={"text-charcoal"}>
                                    <FontAwesomeIcon icon={cycleIcons[item.spaced_repetition_current_index]} />
                                </div>
                                <div className="font-bold">
                                    {dbLoading ? <Skeleton count={1} height={20} /> : 
                                        <>
                                            {(item.spaced_repetition_current_index !== undefined) &&
                                                <>
                                                    Current level is {item.spaced_repetition_current_index}/8
                                                </>
                                            }
                                        </>
                                    } 
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
            }
        </>
    )
}

export default MyVocabularySpacedRepetition;