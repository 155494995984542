import React, { useContext, useState, useEffect } from "react";
import useLanguages from '../hooks/useLanguages';
import {useAuth} from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';

const LanguagesContext = React.createContext();

export function useLanguagesContext() {
    return useContext(LanguagesContext);
};

export function LanguagesProvider({ children }) {
    const {currentUser} = useAuth();
    const {getRealtimeUserLanguagesFromCurrentUser, getAllLanguages, getLastActiveTargetLanguageFromCurrentUser, 
        updateLastActiveTargetLanguageForCurrentUser} = useLanguages();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [allLanguages, setAllLanguages] = useState(null);
    const [userLanguages, setUserLanguages] = useState(null);
    const [lastActiveTargetLanguage, setLastActiveTargetLanguage] = useState(null);
    const [activeUserLanguages, setActiveUserLanguages] = useState(null);
    const [globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage] = useState(null);
    const [globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage] = useState(null);
    const [languageFamilies, setLanguageFamilies] = useState(null);
    const [languageFamilyOptions, setLanguageFamilyOptions] = useState(null);
    const [targetLanguageOptions, setTargetLanguageOptions] = useState(null);
    const [activeTargetLanguageOptions, setActiveTargetLanguageOptions] = useState(null);
    const [activeSourceLanguageOptions, setActiveSourceLanguageOptions] = useState(null);
    const [sourceLanguageOptions, setSourceLanguageOptions] = useState(null);
    const [selectedLanguageFamily, setSelectedLanguageFamily] = useState(null);

    useEffect(()=> {
        if (currentUser === null){
            nullifyUserValues();
        } 
    },[currentUser]); 

    const nullifyUserValues = () => {
        setUserLanguages(null);
        setActiveUserLanguages(null);
        setGlobalSelectedTargetLanguage(null);
        setGlobalSelectedSourceLanguage(null);
        setActiveTargetLanguageOptions(null);
        setActiveSourceLanguageOptions(null);
        setLastActiveTargetLanguage(null);
        setError(null);
    }

    useEffect(()=>{
        if (globalSelectedTargetLanguage !== null && !loading && globalSelectedTargetLanguage !== lastActiveTargetLanguage){
            updateLastActiveTargetLanguageForCurrentUser(globalSelectedTargetLanguage);
        }
    },[globalSelectedTargetLanguage, loading, lastActiveTargetLanguage]);

    useEffect(()=> {
        const fetchData = async () => {
            let r = await getAllLanguages().catch(error=>setError(error));
            setAllLanguages(r);
            if (currentUser !== null){
                getRealtimeUserLanguagesFromCurrentUser(setUserLanguages).catch(error=>setError(error));
            } else {
                setUserLanguages(null);
            }
        }
        fetchData();
    },[currentUser]);

    useEffect(()=>{
        if (allLanguages !== null){
            if (userLanguages !== null){
                let d_target = {};
                userLanguages.forEach((lang)=>{
                    d_target[lang.target_language] = {label:lang.name !== undefined ? lang.name.en : lang.target_language, value:lang.target_language}
                });
                setTargetLanguageOptions([userLanguages.map(lang=>({label:lang.name !== undefined ? lang.name.en : lang.target_language, value:lang.target_language})), d_target]);
            }
            
            let d_source = {};
            allLanguages[1].forEach((lang_code)=>{
                d_source[lang_code] = {label:allLanguages[0][lang_code].name !== undefined ? allLanguages[0][lang_code].name.en : allLanguages[0][lang_code]['ISO_639-1'], value:lang_code}
            })
            
            setSourceLanguageOptions([allLanguages[1].map(lang_code=>({label:allLanguages[0][lang_code].name !== undefined ? allLanguages[0][lang_code].name.en : allLanguages[0][lang_code]['ISO_639-1'], value:lang_code})), d_source]);
            setLanguageFamilies([...new Set(allLanguages[1].map(lang_code=>('language_family' in allLanguages[0][lang_code] && allLanguages[0][lang_code]['language_family'])))].sort());
        }
    },[userLanguages, allLanguages]);

    useEffect(()=>{
        if (languageFamilies !== null){
            let options = [{label: 'All', value: 'all'}];
            for (const l of languageFamilies){
                options.push({
                    label: l,
                    value: l
                })
            }
            setLanguageFamilyOptions(options);
            if (selectedLanguageFamily === null){
                setSelectedLanguageFamily(options[0]);
            }
        }
    },[languageFamilies, selectedLanguageFamily])

    useEffect(()=> {
        if (activeUserLanguages !== null){
            let d = {};
            activeUserLanguages.forEach((lang)=>{
                if (lang.currently_studying === true){
                    d[lang.target_language] = {label:lang.name !== undefined ? lang.name.en : lang.target_language, value:lang.target_language};
                }  
            })
            setActiveTargetLanguageOptions([activeUserLanguages.map(function(lang) {
                return {label:lang.name !== undefined ? lang.name.en : lang.target_language, value:lang.target_language};
            }), d]);
            setActiveSourceLanguageOptions([activeUserLanguages.map(function(lang) {
                return {label:lang.name !== undefined ? lang.name.en : lang.source_language, value:lang.source_language};
            }), d]);
        }
    }, [activeUserLanguages]);

    useEffect(()=> {
        if (allLanguages !== null){
            if (currentUser !== null && userLanguages !== null && activeUserLanguages !== null){
                if (targetLanguageOptions !== null && sourceLanguageOptions !== null && activeTargetLanguageOptions !== null && activeSourceLanguageOptions !== null){
                    setLoading(false);
                }  
            }
            else if (currentUser === null){
                setLoading(false);
            }
        }
    },[targetLanguageOptions, activeTargetLanguageOptions, activeSourceLanguageOptions, activeUserLanguages, sourceLanguageOptions, allLanguages, currentUser, userLanguages]);

    useEffect(()=>{
        if (userLanguages !== null) {
            let active_languages = [];
            if (userLanguages.length > 0){
                active_languages = userLanguages.filter(function(lang) {
                    if (lang.currently_studying === true) {
                    return true;
                    }
                    return false;
                });
            }
            setActiveUserLanguages(active_languages);
        
        }
    },[userLanguages]);

    useEffect(()=>{
        const fetchData = async () => {
            let r = await getLastActiveTargetLanguageFromCurrentUser();
            if (r !== null){
                let found = false;
                for (const lang of activeUserLanguages){
                    if (lang['target_language'] === r){
                        found = true;
                        setGlobalSelectedTargetLanguage(lang['target_language']);
                        setGlobalSelectedSourceLanguage(lang['source_language']);
                        setLastActiveTargetLanguage(lang['target_language']);
                    }
                }
                if (!found){
                    setGlobalSelectedTargetLanguage(activeUserLanguages[0]['target_language']);
                    setGlobalSelectedSourceLanguage(activeUserLanguages[0]['source_language']);
                }
            } else {
                setGlobalSelectedTargetLanguage(activeUserLanguages[0]['target_language']);
                setGlobalSelectedSourceLanguage(activeUserLanguages[0]['source_language']);
            }
        }

        if (activeUserLanguages !== null && activeUserLanguages.length > 0){
            fetchData();
        }   
    },[activeUserLanguages, lastActiveTargetLanguage]);



    const value = {
        allLanguages,
        userLanguages, 
        activeUserLanguages,
        globalSelectedTargetLanguage, 
        setGlobalSelectedTargetLanguage,
        globalSelectedSourceLanguage, 
        setGlobalSelectedSourceLanguage,
        targetLanguageOptions, 
        sourceLanguageOptions, 
        activeTargetLanguageOptions, 
        activeSourceLanguageOptions, 
        languageFamilyOptions, 
        selectedLanguageFamily, 
        setSelectedLanguageFamily, 
        loading, 
        setUserLanguages
    }

    return (
    <LanguagesContext.Provider value={value}>
        {(error === null) && children}
        {error !== null &&
        <>
          <div className="text-black">Error: {error}</div>
        </>
        }
    </LanguagesContext.Provider>
    )
}