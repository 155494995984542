import React, {useState, useEffect} from 'react';
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import Button from '../../components/general/Button';
import useUserSettings from "../../hooks/useUserSettings";
import SimpleErrorBar from '../general/SimpleErrorBar';
import useLogs from '../../hooks/useLogs';

const InputField = ({id, type, text, onChangeFunction, value}) => {

  return (
      <>
          <input key={id} onChange={onChangeFunction} value={value} type={type} className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 px-2 m-2" placeholder={text}></input>
      </>
  )
}

function RegisterComponent() {

    const { 
        createUserDatabaseEntries
    } = useUserSettings();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { signup, currentUser, verifyEmail } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [registerLoading, setRegisterLoading] = useState(false);
    const navigate = useNavigate();
    const emailInputFunction = (e) => {
        error !== "" && setError("");
        setEmail(e.target.value);
    }

    const passwordInputFunction = (e) => {
        error !== "" && setError("");
        setPassword(e.target.value);
    }

/*     useEffect(()=>{
        if (currentUser === null){
            setLoading(false);
        } else {
            if (!registerLoading){
                //navigate("/dashboard");
            }
        }
    },[currentUser, registerLoading]); */

    const {saveErrorLog} = useLogs();

    const registerFunction = async () => {
        if (email === ""){
            setError("Email cannot be blank.");
            return;
        } 
        if (password === ""){
            setError("Password cannot be blank.");
            return;
        }
        setLoading(true);
        setError("");
        setRegisterLoading(true);
        console.log("register");
        await signup(email, password).then(()=>{
            navigate("/onboarding");
        }).catch((err)=>{
            console.log("Error: ", err);
            setError("Failed to register. Please try again or contact Yalango support.");
            saveErrorLog({errorObject: err, functionName: "registerFunction"});
        });
        setLoading(false);
        setRegisterLoading(false);
    }

    const checkIfEnter = (event) => {
        if (event.key === 'Enter') {
            console.log("Enter");
            registerFunction();
        };
    }


    return (
        <>
            {(!loading && !registerLoading) &&
                <>
                    <div className="flex flex-col justify-center" onKeyPress={checkIfEnter}>
                        <div className="m-auto">
                            <h1>Sign up </h1>
                            {error !== "" && 
                                <SimpleErrorBar message={error} />
                            }
                            <InputField id="email_input" type="email" text="Email" value={email} onChangeFunction={emailInputFunction} />
                            <InputField id="password_input" type="password" text="Password" value={password} onChangeFunction={passwordInputFunction} />
                            <div className="flex flex-col align-center text-center">
                                <Button color="green" text="Register" onClick={registerFunction}></Button>
                                <div>Already have an account? <Link to="/login">Log in here</Link></div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {(loading || registerLoading) &&
                <>
                    <div className="h-screen overflow-hidden flex flex-col justify-center text-center text-3xl">
                        <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                    </div>
                </>
            }
        </>
    )
}

export default RegisterComponent;
