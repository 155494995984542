import React from "react";
import { Link } from "react-router-dom";


const LanguageTools_zh = () => {

    return (
        <div className="h-full pb-6 flex flex-col gap-4">
            <h2>Useful tools & resources for Chinese</h2>
            <div className="flex flex-col gap-3 place-items-center h-full text-sm ">
                <Link to="/learn/Mandarin-Chinese/hanzi-to-pinyin">Hanzi to pinyin converter</Link>
                <Link to="/decks?target=zh">Decks from other users in Chinese</Link>
                <Link to="/texts?target=zh">AI texts in Chinese</Link>

                <div className="text-center mt-auto text-xs justify-self-end ">
                    PS! Remember that you can <span className="font-bold">turn on and off pinyin</span> in Chinese decks by going to your <Link to="/my-languages">Language settings</Link>.
                </div>
              
            </div>
        </div>
    );
};

export default LanguageTools_zh;