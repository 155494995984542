import React, {useState, useEffect} from 'react';
import YalangoHappySvg from '../../../../assets/svg/yalango/yalango_thumbs_up_without_circle.svg';
import YalangoSadSvg from '../../../../assets/svg/yalango/yalango_sad_without_circle.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStars} from '@fortawesome/pro-duotone-svg-icons';
import {faSparkles} from '@fortawesome/pro-duotone-svg-icons';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';
import Button from '../../../general/Button';
import usePdfs from '../../../../hooks/usePdfs';
import { useLanguagesContext } from '../../../../contexts/LanguagesContext';


const ScoreScreen = ({score, contentType, cards, deck, nextLessonId, type, designMode, nbOfAnswers, backToPlayPageLink, chosenBackToGamesFunction, playAgainClick, reviewMistakesClick, nextCourseLessonClick, mistakes, reviewFinished, courseId}) => {
    const [feedback, setFeedback] = useState(null);

    const {generateDeckListPdf, generateDeckQuizPdf} = usePdfs();
    const {allLanguages} = useLanguagesContext();

    useEffect(()=>{
        if (score !== null && nbOfAnswers !== null){
            if (reviewFinished === true){
                setFeedback("Great job revising your mistakes! Keep up the good work.");
            }

            else if (score === nbOfAnswers){
                setFeedback("Wow, fantastic job!");
            }
        
            else if (score === 0){
                setFeedback("Let's try again... You can do it!");
            }
        
            else if (score === 1){
                setFeedback("You got one exercise correct - let's see if you can improve it next time!");
            }

            else if (score/nbOfAnswers < 0.3){
                setFeedback("You have several correct answers, but there's still room for improvement!");
            }
        
            else if (score/nbOfAnswers < 0.5){
                setFeedback("Keep it up!");
            }
        
            else if (score/nbOfAnswers < 0.6){
                setFeedback("Nice one, you got more than 50% correct!");
            }
        
            else if (score/nbOfAnswers < 0.8){
                setFeedback("This is looking really good!");
            }
        
            else if (score/nbOfAnswers < 0.9){
                setFeedback("Wow, you're not far from perfect!");
            }
        
            else if (score/nbOfAnswers < 1){
                setFeedback("You're so close to 100% - let's try again and I'm sure you'll make it!");
            }
        }
    },[score, nbOfAnswers]);

    /*
                <div>
                    <a target="_blank" rel="noreferrer" href={"https://twitter.com/intent/tweet?url=https%3A%2F%2Fyalango.com&via=yalango_app&text="+encodeURI("I just reviewed my vocabulary on Yalango and got " + score + " out of " + nbOfAnswers + " cards correct!")}>Share on Twitter</a>
                </div>
    */

    const generateDeckListPdfClick = () => {
        generateDeckListPdf({title: deck?.name ? deck.name : "PDF", items: cards.map(item => ({source: item.source, target: item.target})), targetLanguage: allLanguages[0][deck["target_ISO_639-1"]]['name']['en'], sourceLanguage:allLanguages[0][deck["source_ISO_639-1"]]['name']['en'], deckId: deck.id});
    }

    const generateDeckQuizPdfClick = () => {
        generateDeckQuizPdf({title: deck?.name ? deck.name : "PDF", items: cards.map(item => ({source: item.source, target: item.target})), targetLanguage: allLanguages[0][deck["target_ISO_639-1"]]['name']['en'], sourceLanguage:allLanguages[0][deck["source_ISO_639-1"]]['name']['en'], deckId: deck.id});
    }

  return (
    <>
        <div className="flex flex-col justify-center text-center gap-4 h-full">
            {reviewFinished === true ?
                <>
                    <div className="">
                        <img src={YalangoHappySvg} alt="Yalango mascot" className="w-3/6 m-auto p-4 sm:w-64 mx-auto my-0" />
                    </div>
                </>
            :
                <>
                <div className="">
                    <img src={score/nbOfAnswers < 0.3 ? YalangoSadSvg : YalangoHappySvg} alt="Yalango mascot" className="w-3/6 m-auto p-4 sm:w-64 mx-auto my-0" />
                </div>
                </>
            }
            
            <div className="text-persian-green font-bold text-sm md:text-lg">
                {feedback !== null && <>"{feedback}"</>}
            </div>
            <div className="mb-2 whitespace-nowrap flex text-lg sm:text-5xl flex-row justify-center gap-4">
                {score === nbOfAnswers &&
                <div className="text-sandy-brown">
                    <FontAwesomeIcon icon={faStars} className="fa-beat" />
                </div>
                }
                <div className="font-bold text-persian-green">
                    Score: {score}/{nbOfAnswers}
                </div>
                {score === nbOfAnswers &&
                <div className="text-sandy-brown">
                    <FontAwesomeIcon icon={faStars} className="fa-beat" style={{'--fa-animation-direction': 'reverse'}} />
                </div>
                }
            </div>
            
            <div className="flex flex-col gap-2">
                {(reviewMistakesClick !== undefined && mistakes !== undefined && mistakes !== null && mistakes.length > 0) &&
                    <motion.div whileHover={{scale:1.03}} onClick={reviewMistakesClick} className="flex flex-row whitespace-nowrap justify-center gap-2 group bg-burnt-sienna text-white font-bold rounded-lg p-3 shadow-lg text-xl cursor-pointer">
                        <div className="text-yellow-300 fa-beat">
                            <FontAwesomeIcon icon={faSparkles} />
                        </div>
                        <div>
                            Review {mistakes.length} mistake{mistakes.length === 1 ? '' : 's'}
                        </div>
                    </motion.div>
                }
                {type !== "review-mistakes" && type !== "course" &&
                    <motion.div whileHover={{scale:1.03}} onClick={playAgainClick} className=" bg-persian-green whitespace-nowrap text-white font-bold rounded-lg p-3 shadow-lg text-lg cursor-pointer">
                        Play again with new cards
                    </motion.div>
                }
                {type === "course" && 
                    <>
                        {nextLessonId !== null ?
                            <Link className="no-underline" to={`/course/${courseId}/lesson/${nextLessonId}`} >
                                <motion.div whileHover={{scale:1.03}} onClick={nextCourseLessonClick} className=" bg-persian-green whitespace-nowrap text-white font-bold rounded-lg p-3 shadow-lg text-lg cursor-pointer">
                                    Go to next lesson
                                </motion.div>
                            </Link>
                            :
                            <div className="font-bold text-persian-green text-lg">
                                This was the last lesson in the course. Congratulations on completing it! 🎉
                            </div>  
                        }
                        <motion.div whileHover={{scale:1.03}} onClick={playAgainClick} className=" bg-charcoal whitespace-nowrap text-white font-bold rounded-lg p-3 shadow-lg text-lg cursor-pointer">
                            Practice this lesson again
                        </motion.div>
                       
                    </>
                }
                
                {designMode !== "custom" ?
                    <>
                        <Link className="no-underline" to={backToPlayPageLink}>
                            <motion.div whileHover={{scale:1.03}} className=" bg-charcoal text-white font-bold whitespace-nowrap rounded-lg p-3 shadow-lg text-lg cursor-pointer">
                                {type === "review-mistakes" ? "Go back" : type === "course" ? "Back to course" : "Back to games"}
                            </motion.div>
                        </Link>
                    </>
                :
                    <>
                        <motion.div onClick={chosenBackToGamesFunction} whileHover={{scale:1.03}} className=" bg-charcoal text-white font-bold whitespace-nowrap rounded-lg p-3 shadow-lg text-lg cursor-pointer">
                            End game
                        </motion.div>    
                    </>
                }
                {contentType === "deck" &&
                    <div className="mt-6">
                        <h2>Downloads (PDF)</h2>
                        <div className="flex flex-row justify-center gap-4">
                            <div className="w-1/2">
                                <motion.div whileHover={{scale:1.03}} onClick={generateDeckListPdfClick} className=" bg-charcoal whitespace-nowrap text-white font-bold rounded-lg p-3 shadow-lg text-lg cursor-pointer">
                                    Study list
                                </motion.div> 
                            </div>
                            <div className="w-1/2">
                                <motion.div whileHover={{scale:1.03}} onClick={generateDeckQuizPdfClick} className=" bg-charcoal whitespace-nowrap text-white font-bold rounded-lg p-3 shadow-lg text-lg cursor-pointer">
                                    Quiz
                                </motion.div> 
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </>
  )
}

export default ScoreScreen;