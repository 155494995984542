import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import useDecks from '../hooks/useDecks';
import {Link} from 'react-router-dom';
import SortByButton from '../components/general/sorting/SortByButton';
import Skeleton from 'react-loading-skeleton';
import {useLanguagesContext} from '../contexts/LanguagesContext';
import useQueryStrings from '../hooks/useQueryStrings';
import {faPencil} from '@fortawesome/pro-duotone-svg-icons';
import {faDownload} from '@fortawesome/pro-solid-svg-icons';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons';
import Icon from '../components/general/Icon';
import NoActiveTargetLanguage from '../components/errors/NoActiveTargetLanguage';
import Select from 'react-select';
import CountryFlag from '../components/languages/CountryFlag';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDiceD20} from '@fortawesome/pro-duotone-svg-icons';
import {faLoader} from '@fortawesome/pro-duotone-svg-icons';
import {faChevronDown} from '@fortawesome/pro-duotone-svg-icons';
import {faAngleRight} from '@fortawesome/pro-solid-svg-icons';
import {faHeadSideBrain} from '@fortawesome/pro-solid-svg-icons';
import {faAngleLeft} from '@fortawesome/pro-solid-svg-icons';
import {faChevronUp} from '@fortawesome/pro-duotone-svg-icons';
import {faPlay} from '@fortawesome/pro-solid-svg-icons';
import {faRankingStar} from '@fortawesome/pro-duotone-svg-icons';
import {faGhost} from '@fortawesome/pro-duotone-svg-icons';
import {useUserStatisticsContext} from '../contexts/UserStatisticsContext'; 
import {motion} from 'framer-motion';
import {faTableColumns} from '@fortawesome/pro-duotone-svg-icons';
import ProgressMenu from '../components/menus/ProgressMenu';

const MyVocabulary = () => {

  let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions} = useLanguagesContext();
  const {fetchVocabularyInTargetLanguage, 
  fetchVocabularyMetadataInTargetLanguage} = useDecks();
  let {addAndRemoveQueryStrings} = useQueryStrings();
  const [expandedVocabItem, setExpandedVocabItem] = useState(null);
  const [dbLoading, setDbLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const { refreshVocabularyMetadata,
    vocabularyMetadata} = useUserStatisticsContext();

  console.log(vocabularyMetadata);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisibleItem, setLastVisibleItem] = useState(null);
  const [firstVisibleItem, setFirstVisibleItem] = useState(null);
  const [numberOfDeletedItems, setNumberOfDeletedItems] = useState(0);

  const filterOptions = [{label: "My best items", value: "best"}, {label: "My worst items", value:"worst"}]
  const [filterValue, setFilterValue] = useState(filterOptions[0]);

  const [vocabulary, setVocabulary] = useState(null);

  const [selectedTab, setSelectedTab] = useState("statistics");

  const mql = window.matchMedia('(max-width: 640px)');
  let [mobileView, setMobileView] = useState(mql.matches);
  window.addEventListener('resize', () => {
      let width = window.matchMedia('(max-width: 640px)');
      console.log(mobileView);
      if (width.matches){
          if (mobileView === false){
              setMobileView(true);
          }
      } else {
          if (mobileView === true){
              setMobileView(false);
          }
      }
      
    });

  useEffect(()=>{
    const fetchData = async () => {
      !dbLoading && setDbLoading(true);
      let r = null;
      await refreshVocabularyMetadata();
      if (filterValue.value === "best"){
        r = await fetchVocabularyInTargetLanguage(globalSelectedTargetLanguage, "best", itemsPerPage);
      } 
      else if (filterValue.value === "worst"){
        r = await fetchVocabularyInTargetLanguage(globalSelectedTargetLanguage, "worst", itemsPerPage);
      }
      setVocabulary(r['vocabulary']);
      setCurrentPage(1);
      setLastVisibleItem(r['lastVisible']);
      setFirstVisibleItem(r['firstVisible']);
      setNumberOfDeletedItems(r['numberOfAllSourcesDeletedItems']);
      setDbLoading(false);
    }
    if (globalSelectedTargetLanguage !== null){
      fetchData();
    }
  },[globalSelectedTargetLanguage, filterValue]);

  const changeSelectedLanguage = (option) => {
    addAndRemoveQueryStrings([{name:'lang', value: option.value}],[]); // removes
    setGlobalSelectedTargetLanguage(option.value);
    if (activeUserLanguages.length > 0 ){
        for (const lang of activeUserLanguages){
            if (lang['target_language'] === option.value){
                setGlobalSelectedSourceLanguage(lang['source_language']);
            }
        }
    }
  }

  const changeVocabFilterOption = (option) => {
    setCurrentPage(1);
    setLastVisibleItem(null);
    setFirstVisibleItem(null);
    setFilterValue(option);
  }

  const nextPageClick = async () => {
    if (vocabulary !== null){
        await fetchItems("next");
    }
  }

  const previousPageClick = async () => {
      if (vocabulary !== null){
          await fetchItems("last");
      }
  }

  const fetchItems = async (type) => {
    let r = null;
    setDbLoading(true);
    window.scrollTo(0, 0);
    if (type === "next"){
      r = await fetchVocabularyInTargetLanguage(globalSelectedTargetLanguage, filterValue.value, itemsPerPage, lastVisibleItem, type);
    }
    else if (type === "last"){
      r = await fetchVocabularyInTargetLanguage(globalSelectedTargetLanguage, filterValue.value, itemsPerPage, firstVisibleItem, type);
    }
    if (r['vocabulary'].length > 0 && r['firstVisible'] !== null && r['lastVisible'] !== null){
      setVocabulary(r['vocabulary']);
      setLastVisibleItem(r['lastVisible']);
      setFirstVisibleItem(r['firstVisible']);
      if (type === "next"){
        setCurrentPage(currentPage + 1);
      } else {
          if (currentPage > 0 ){
              setCurrentPage(currentPage - 1);
          }
      }
    }
    setDbLoading(false);
  }

  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  
  const openShowOptionsMenu = (e) => {
    e.stopPropagation();
    setShowOptionsMenu(!showOptionsMenu);
  }
  
  const closeShowOptionsMenu = () => {
    setShowOptionsMenu(false);
  }

  const generalBackgroundClick = () => {
    closeShowOptionsMenu();
}

  console.log(vocabulary);

  return (
    <>
        <Helmet>
            <title>My Vocabulary - Yalango</title>
            <meta name="description" content={"My vocabulary on Yalango."} />
        </Helmet>
        
          {(activeUserLanguages !== null && activeUserLanguages.length === 0) ?
            <>
              <NoActiveTargetLanguage />
            </>
            :
            <>
            <div onClick={generalBackgroundClick} className=" ">
            <h1>My progress</h1>
            <ProgressMenu tabValue={"my-vocabulary"} />

              <div onClick={(e)=>openShowOptionsMenu(e)} className="absolute sm:right-4 sm:top-4 right-2 top-2 p-2 cursor-pointer text-xl">
                  <Icon color="charcoal" icon={<FontAwesomeIcon icon={faEllipsisV} />} /> 
              </div>
            {showOptionsMenu && 
                    <>
                        <div className="absolute sm:right-8 sm:top-10 right-6 top-6 overflow-visible text-base  z-10 rounded-lg shadow-xl bg-white ">
                            <div className="flex text-sm whitespace-nowrap font-bold text-gray-700 flex-col justify-start place-items-start">
                                <Link to={'/my-vocabulary/spaced-repetition'} className="no-underline w-full" >
                                    <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                        <div>
                                            <Icon color="yellow" icon={<FontAwesomeIcon icon={faHeadSideBrain} />} />
                                        </div>
                                        <div>
                                            Spaced repetition queue
                                        </div>
                                    </div>
                                </Link>
                                <Link to={'/my-vocabulary/export'} className="no-underline w-full" >
                                    <div className="flex flex-row gap-3 hover:bg-gray-100 w-full rounded-lg  p-3">
                                        <div>
                                            <Icon color="charcoal" icon={<FontAwesomeIcon icon={faDownload} />} />
                                        </div>
                                        <div>
                                            Export data
                                        </div>
                                    </div>
                                </Link>                       
                            </div>
                        </div>
                    </>
                }
            <div className=" flex flex-col gap-4 justify-center place-items-center">
                {(vocabularyMetadata !== null && vocabularyMetadata.hasOwnProperty(globalSelectedTargetLanguage)) &&
                    <>
                    <div className="place-self-start flex flex-row justify-between sm:w-3/4 m-auto w-full">
                      <div className="text-xs text-left  text-gray-600">
                        Total: <span className="font-bold">{vocabularyMetadata[globalSelectedTargetLanguage].hasOwnProperty('active_items_doc_ids') ? vocabularyMetadata[globalSelectedTargetLanguage]['active_items_doc_ids'].length  : vocabularyMetadata[globalSelectedTargetLanguage]['number_of_items']}</span> {vocabularyMetadata['number_of_items'] === 1 ? 'item' : 'items'}
                      </div>
                      <div>
                        <SortByButton textSize={"sm"} onChange={changeVocabFilterOption} value={filterValue} options={filterOptions} />
                      </div>
                    </div>
                    </>
                  }
                  {dbLoading && [...Array(itemsPerPage)].map((n, index)=>(
                    <>
                      <div key={"skeleton_"+index} className="w-full sm:w-3/4 h-full">
                        <Skeleton count={1} height={150} />
                      </div>
                    </>
                  ))}
                  {(!dbLoading && allLanguages !== null) && [...Array(itemsPerPage)].map((n, index)=>(
                        <>
                        <Link key={"link_"+index} to={"/my-vocabulary/" + globalSelectedTargetLanguage + "/item/"+vocabulary[index]?.id} className="w-full no-underline sm:w-3/4">
                        {vocabulary !== null && vocabulary.length > index &&
                          <>
                            {vocabulary[index].type === "deck" &&
                              <>
                                <div key={"item_"+index} className={(dbLoading ? "opacity-30 " : "") + " text-gray-500 relative bg-white  hover:text-gray-700 hover:shadow-xl font-bold shadow-lg rounded-xl px-4 py-8  text-center "}>
                                  <div className="flex flex-col mb-3 sm:mb-0 gap-4 md:flex-row justify-between place-items-center">
                                  <div className="absolute p-2 left-2 top-1">
                                    <div className={(filterValue.value === "best" ? "text-sandy-brown " : "text-purple-500 ") + "flex flex-row gap-1 text-base"}>
                                    
                                      {(index === 0 && currentPage === 1) ?
                                      <div className="text-lg">
                                        <FontAwesomeIcon icon={filterValue.value === "best" ? faRankingStar : faGhost} />
                                      </div>
                                      :
                                      <div>
                                        {(index+1)+((currentPage-1)*itemsPerPage)}.
                                      </div>
                                      }
                                    </div>
                                  </div>
                                  <div className="md:pl-20 flex flex-col place-items-start  gap-6 justify-center">
                                    <div className="flex flex-row place-items-center gap-3">
                                      <div className="w-8 rounded-lg shadow-xl overflow-hidden">
                                        <CountryFlag flagName={allLanguages[0][vocabulary[index]['target_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][vocabulary[index]['target_ISO_639-1']]['country_flag']} />
                                      </div>
                                      <div className="whitespace-nowrap">
                                        {dbLoading ? <Skeleton count={1} height={20} /> : <>{vocabulary[index].target}</>}
                                      </div>
                                    </div>
                                    <div className="flex flex-row place-items-center gap-2">
                                      <div className="w-8 rounded-lg shadow-xl overflow-hidden">
                                        {(vocabulary[index]['source_ISO_639-1'] !== undefined && vocabulary[index]['source_ISO_639-1'] !== "" && vocabulary[index]['source_ISO_639-1'] !== null ) &&
                                          <>
                                            <CountryFlag flagName={allLanguages[0][vocabulary[index]['source_ISO_639-1']]['name']['en']} countryCode={allLanguages[0][vocabulary[index]['source_ISO_639-1']]['country_flag']} />
                                          </>
                                        }
                                      </div>
                                      <div className="whitespace-nowrap">
                                      {dbLoading ? <Skeleton count={1} height={20} /> : <>{vocabulary[index].source}</>}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="sm:mt-0 mt-2 flex w-full flex-row justify-between sm:place-items-center sm:flex-col gap-6 sm:gap-2 text-sm">
                                    <div className="flex flex-row justify-center sm:justify-end w-full gap-2">
                                      <div className="text-sandy-brown">
                                        <FontAwesomeIcon icon={faDiceD20} />
                                      </div>
                                      <div>
                                        {dbLoading ? <Skeleton count={1} height={20} /> : <>{vocabulary[index].total_statistics.percentage.correct !== undefined && <>{vocabulary[index].total_statistics.percentage.correct.toFixed(0)}</>}% correct</>} 
                                      </div>
                                    </div>
                                    <div className="flex flex-row justify-center sm:justify-end w-full gap-2">
                                      <div className="text-persian-green">
                                        <FontAwesomeIcon icon={faDiceD20} />
                                      </div>
                                      <div>
                                        {dbLoading ? <Skeleton count={1} height={20} /> : <>{vocabulary[index].total_statistics.number_of_answers !== undefined && <>{vocabulary[index].total_statistics.number_of_answers}</>} answers</>} 
                                      </div>
                                    </div>
                                  </div>
                                  
                                  </div>
                                  <div onClick={()=>expandedVocabItem === vocabulary[index].id ? setExpandedVocabItem(null) : setExpandedVocabItem(vocabulary[index].id)} className="absolute hidden cursor-pointer bottom-0 text-sm right-0 p-3 flex flex-row gap-2">
                                    <div>
                                      More details
                                    </div>
                                    <div className="">
                                      <FontAwesomeIcon icon={expandedVocabItem === vocabulary[index].id ? faChevronUp : faChevronDown} />
                                    </div>
                                  </div>
                                </div>
                                {expandedVocabItem === vocabulary[index].id && 
                                  <>
                                  
                                    <div className="w-full sm:w-3/4 p-8 shadow-xl">
                                      <div className="mb-4 flex flex-row gap-1 justify-start">
                                          <motion.div onClick={()=>setSelectedTab("statistics")} whileHover={{scale:1.03}} className={(selectedTab==="statistics" ? 'bg-persian-green text-white font-bold ' : 'bg-white ') + "  px-3 py-2 cursor-pointer rounded-sm shadow-sm"}>
                                              Statistics
                                          </motion.div>
                                          <motion.div onClick={()=>setSelectedTab("metadata")} whileHover={{scale:1.03}} className={(selectedTab==="metadata" ? 'bg-persian-green text-white font-bold ' : 'bg-white ') + "  px-3 py-2 cursor-pointer rounded-sm shadow-sm"}>
                                              Metadata
                                          </motion.div>
                                      </div>
                                      {selectedTab === "metadata" &&
                                        <>
                                          <div className="">
                                            <div className="text-xs ">
                                              <div>Found in {vocabulary[index].deck_ids.length === 1 ? "deck" : "decks"}:</div> 
                                              <div className="h-16 overflow-y-auto overflow-x-hidden">
                                              {vocabulary[index].deck_ids.map((v, index)=>(
                                                <>
                                                  <div key={"deck_link_"+index} className="my-2">
                                                    <Link to={"/my-decks/"+v}>{v}</Link>
                                                  </div>
                                                </>
                                              ))}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      }
                                      {selectedTab === "statistics" &&
                                        <>
                                      <div className="flex flex-col gap-2 sm:flex-row justify-evenly"> 
                                        {'flashcards_statistics' in vocabulary[index] &&
                                          <>
                                        <div className="">
                                            <div className="text-lg font-bold text-gray-700 mb-2">Flashcards</div>
                                            <div className="flex flex-col gap-2">
                                              <div className="flex flex-row gap-4">
                                                <div className="font-bold text-gray-700">
                                                  Total correct
                                                </div> 
                                                <div>
                                                  {vocabulary[index].flashcards_statistics.percentage.correct.toFixed(0)}% of {vocabulary[index].flashcards_statistics.number_of_answers.total} {vocabulary[index].flashcards_statistics.number_of_answers.total === 1 ? 'try' : 'tries'}
                                                </div>
                                              </div>
                                              <div className="flex flex-row gap-4 ">
                                                <div className="font-bold text-gray-700">
                                                  - Target mode
                                                </div> 
                                                <div>
                                                  {vocabulary[index].flashcards_statistics.percentage.correct_target_first.toFixed(0)}% of {vocabulary[index].flashcards_statistics.number_of_answers.total_target_first} {vocabulary[index].flashcards_statistics.number_of_answers.total_target_first === 1 ? 'try' : 'tries'}
                                                </div>
                                              </div>
                                              <div className="flex flex-row gap-4 ">
                                                <div className="font-bold text-gray-700">
                                                  - Source mode
                                                </div> 
                                                <div>
                                                  {vocabulary[index].flashcards_statistics.percentage.correct_source_first.toFixed(0)}% of {vocabulary[index].flashcards_statistics.number_of_answers.total_source_first} {vocabulary[index].flashcards_statistics.number_of_answers.total_source_first === 1 ? 'try' : 'tries'}
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                        </>
                                        }
                                        {'quiz_statistics' in vocabulary[index] &&
                                          <>
                                            <div className="">
                                                <div className="text-lg font-bold text-gray-700  mb-2">Quiz</div>
                                                <div className="flex flex-col gap-2">
                                                  <div className="flex flex-row gap-4 ">
                                                    <div className="font-bold text-gray-700">
                                                      Total correct
                                                    </div> 
                                                    <div>
                                                      {vocabulary[index].quiz_statistics.percentage.correct.toFixed(0)}% of {vocabulary[index].quiz_statistics.number_of_answers} {vocabulary[index].quiz_statistics.number_of_answers === 1 ? 'try' : 'tries'}
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                          </>
                                        }
                                        
                                          {'input_statistics' in vocabulary[index] &&
                                            <>
                                              <div className="">
                                                <div className="text-lg font-bold text-gray-700  mb-2">Input</div>
                                                <div className="flex flex-col gap-2">
                                                  <div className="flex flex-row gap-4 ">
                                                    <div className="font-bold text-gray-700">
                                                      Total correct
                                                    </div> 
                                                    <div>
                                                      {vocabulary[index].input_statistics.percentage.correct.toFixed(0)}% of {vocabulary[index].input_statistics.number_of_answers.total} {vocabulary[index].input_statistics.number_of_answers.total === 1 ? 'try' : 'tries'}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          }
                                        {(!('flashcards_statistics' in vocabulary[index]) && !('quiz_statistics' in vocabulary[index]) && !('input_statistics' in vocabulary[index])) &&
                                          <>
                                            <div className="italic">
                                              No statistics yet.
                                            </div>
                                          </>
                                        }
                                      </div>
                                        </>
                                      }
                                    </div>
                                  </>
                                }
                              </>
                            }
                            {vocabulary[index].type === "table-row" &&
                              <>
                                <div key={"item_"+index} className={(dbLoading ? "opacity-30 " : "") + (index === 0 ? (filterValue.value === "best" ? ' bg-white ' : ' bg-white ') : ' bg-white ') +" relative text-gray-700 font-bold shadow-xl rounded-xl px-4 py-8 w-full sm:w-3/4 text-center "}>
                                  <div className="flex flex-col mb-3 sm:mb-0 gap-4 md:flex-row justify-between place-items-center">
                                    <div className="absolute p-2 left-2 top-1">
                                      <div className={(filterValue.value === "best" ? "text-sandy-brown " : "text-purple-500 ") + "flex flex-row gap-1 text-base"}>
                                      
                                        {(index === 0 && currentPage === 1) ?
                                        <div className="text-lg">
                                          <FontAwesomeIcon icon={filterValue.value === "best" ? faRankingStar : faGhost} />
                                        </div>
                                        :
                                        <div>
                                          {(index+1)+((currentPage-1)*itemsPerPage)}.
                                        </div>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex flex-row justify-center w-full overflow-auto gap-2 text-base">
                                        <div className="text-beautiful-blue">
                                          <FontAwesomeIcon icon={faTableColumns} />
                                        </div>
                                        
                                        {vocabulary !== null && vocabulary[index] !== undefined && vocabulary[index].hasOwnProperty('row_values') && Object.entries(vocabulary[index].row_values).map((key, index)=>(
                                            <>
                                              <div key={"col_"+index}>
                                                {key[1]}
                                              </div>
                                            </>
                                          ))}
                                  </div>
                                  <div className="sm:mt-0 mt-2 flex w-full flex-row justify-between sm:place-items-center sm:flex-col gap-6 sm:gap-2 text-sm">
                                    <div className="flex flex-row justify-center sm:justify-end w-full gap-2">
                                      <div className="text-sandy-brown">
                                        <FontAwesomeIcon icon={faDiceD20} />
                                      </div>
                                      <div>
                                        {dbLoading ? <Skeleton count={1} height={20} /> : <>{vocabulary[index].total_statistics.percentage.correct !== undefined && <>{vocabulary[index].total_statistics.percentage.correct.toFixed(0)}</>}% correct</>} 
                                      </div>
                                    </div>
                                    <div className="flex flex-row justify-center sm:justify-end w-full gap-2">
                                      <div className="text-persian-green">
                                        <FontAwesomeIcon icon={faDiceD20} />
                                      </div>
                                      <div>
                                        {dbLoading ? <Skeleton count={1} height={20} /> : <>{vocabulary[index].total_statistics.number_of_answers !== undefined && <>{vocabulary[index].total_statistics.number_of_answers}</>} answers</>} 
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            }
                          </>
                        }
                        </Link>
                        </>
                  ))}
                  <div className="flex w-full sm:w-3/4 m-auto flex-row justify-between gap-4 mb-3 text-3xl">
                      <div onClick={previousPageClick} className="cursor-pointer">
                          {currentPage !== 1 &&
                              <>
                                  <div className="flex flex-row place-items-center gap-2">
                                      <FontAwesomeIcon icon={faAngleLeft} /> 
                                      <div className="text-base">
                                          Previous page
                                      </div>
                                  </div>
                              </>
                          }
                      </div>
                      
                      {vocabularyMetadata !== null &&
                        <div onClick={nextPageClick} className="cursor-pointer">
                            {(currentPage !== Math.ceil(vocabularyMetadata.number_of_items/25)) &&
                                <>
                                    <div className="flex flex-row place-items-center gap-2">
                                        <div className="text-base">
                                            Next page
                                        </div>
                                        <FontAwesomeIcon icon={faAngleRight} /> 
                                    </div>
                                </>
                            }
                        </div>
                      }
                  </div>
                  {(vocabulary !== null && vocabulary.length === 0) &&
                    <>
                      <div className="italic py-8">
                        Your vocabulary is currently empty. Begin by adding words to your <Link to={"/my-decks"}>decks</Link> in order to track your vocabulary.
                      </div>
                    </>
                  }
            </div>
            </div>
            </>
          }
        
        
    </>
  )
}

export default MyVocabulary;