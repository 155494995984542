import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import NormalTextInput from '../general/input/NormalTextInput';
import Button from '../../components/general/Button';
import { useAuth } from "../../contexts/AuthContext";
import useLogs from '../../hooks/useLogs';

const ForgottenPasswordComponent = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { resetPassword } = useAuth();
  const {saveErrorLog} = useLogs();

  const emailInputFunction = (e) => {
    setEmail(e.target.value);
  }

  const resetPasswordClick = async () => {
    if (email !== ""){
      setLoading(true);
      await resetPassword(email).then(()=>{
        setSuccess(true);
      }).catch(err=>{
        console.log(err);
        saveErrorLog({errorObject: err, functionName: "resetPasswordClick"});
      });
      setLoading(false);
    }
  }

  return (
    <>
        <div className="w-1/2 h-full m-auto flex flex-col gap-3 place-items-center justify-center" >
                <h1>Reset your password</h1>
                {!success &&
                  <>
                    <div className="text-sm italic">Fill in your email below and we'll send you a link to reset your password.</div>
                    <div className="w-72">
                      <NormalTextInput autoFocus={true} type="email" value={email} onChange={emailInputFunction} />
                    </div>
                    {loading ? 
                        <><div className="text-center">Loading...</div></>
                      :
                      <>
                        <Button color="green" text="Reset password" onClick={resetPasswordClick} />
                        </>
                      }
                  </>
                }
                {success && 
                  <>
                    Please check your inbox for a link to reset your password on Yalango.
                    <div className="text-center">
                      <Link to="/login">
                        <Button color="green" text="Log in" />
                      </Link>
                    </div>
                  </>
                }
        </div>  
    </>
  )
}

export default ForgottenPasswordComponent;